import styled from 'styled-components';

import Container from 'components/common/Container';

export const wrapper = styled(Container)`
  padding: 0 1rem;
`;

export const buttonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
