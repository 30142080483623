import literalsNavigation from 'localization/navigation';

import config from 'config';

import NotFountPage from 'components/NotFoundPage';
import HomePage from 'components/home/HomePage';
import CountryInfoPage from 'components/info/CountryInfoPage';
import GeneralPage from 'components/general/GeneralPage';
import ArticlePage from 'components/article/ArticlePage';
import LoginPage from 'components/auth/LoginPage';
import LocationPage from 'components/location/LocationPage';

const HOST_NAME = window.location.hostname;

export const routes = [
  {
    path: '/',
    component: HomePage,
    pageProps: {
      title: literalsNavigation.aboutProject
    }
  },
  {
    path: '/general',
    component: GeneralPage,
    pageProps: {
      title: literalsNavigation.generalRecommendations
    }
  },
  {
    path: '/info',
    component: CountryInfoPage,
    pageProps: {
      title: literalsNavigation.countryInformation
    }
  },
  {
    path: '/article/:slug',
    component: ArticlePage,
    pageProps: {
      title: literalsNavigation.article
    }
  },
  {
    path: '/location/:country/:code',
    component: LocationPage,
    pageProps: {
      title: literalsNavigation.locations
    }
  },
  {
    path: '/*',
    component: NotFountPage,
    pageProps: {
      title: literalsNavigation.notFoundPage
    }
  }
];

if (HOST_NAME !== config.productionBaseUrl) {
  routes.push({
    path: '/login',
    component: LoginPage,
    pageProps: {
      title: literalsNavigation.login
    }
  });
}
