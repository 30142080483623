import styled from 'styled-components';

import Container from 'components/common/Container';

import {mediaQueries} from 'styles/shared';

export const wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const container = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 1rem;

  @media ${mediaQueries.desktop} {
    width: 60%;
  }
`;

export const notFound = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;
