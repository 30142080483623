import literalsCommon from 'localization/common';

import AppIcon, {IconName} from 'components/common/AppIcon';

import {colors} from 'styles/shared';

import * as styled from './SectionComponent.styled';

export type sectionType = 'default' | 'note' | 'tip' | 'warning' | 'danger';

function SectionComponent(props) {
  const type: sectionType = props.type || 'default';

  let icon: IconName | undefined = undefined;
  let color: string = '';
  let title: string = '';

  switch (type) {
    case 'note':
      icon = 'info';
      color = colors.primary;
      title = literalsCommon.note;
      break;

    case 'tip':
      icon = 'tip';
      color = colors.success;
      title = literalsCommon.tip;
      break;

    case 'warning':
      icon = 'warning';
      color = colors.warning;
      title = literalsCommon.warning;
      break;

    case 'danger':
      icon = 'danger';
      color = colors.danger;
      title = literalsCommon.danger;
      break;

    default:
      break;
  }

  if (!icon) {
    return <styled.section type={type}>{props.children}</styled.section>;
  }

  if (props.title) title = props.title;

  return (
    <styled.section type={type}>
      <styled.title>
        <AppIcon name={icon} size="lg" color={color} />
        {title}
      </styled.title>

      {props.children}
    </styled.section>
  );
}

export default SectionComponent;
