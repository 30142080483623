import {useAppDispatch} from 'hooks';

import {openArticle} from 'reducers/articleSlice';

import navigationHelper from 'helpers/navigationHelper';

import EditorIconsForArticle from 'components/common/EditorIconsForArticle';

import * as styled from './EditorInfoItem.styled';

interface Props {
  item: Article;
  handleEditDocument: (item: Article) => void;
  handleShowContentModal: (item: Article) => void;
  handleShowConfirmation: (item: Article) => void;
}

function EditorInfoItem({item, handleEditDocument, handleShowContentModal, handleShowConfirmation}: Props) {
  const dispatch = useAppDispatch();

  const articlePath = navigationHelper.getRelativeUrlPathForArticle(item.urlSlug);

  function openLink(e) {
    if (e) e.preventDefault();
    dispatch(openArticle(item.urlSlug));
  }

  return (
    <styled.container key={item.id}>
      <a href={articlePath} onClick={openLink}>
        {item.title}
      </a>

      <EditorIconsForArticle
        article={item}
        handleEditDocument={handleEditDocument}
        handleShowContentModal={handleShowContentModal}
        handleShowConfirmation={handleShowConfirmation}
      />
    </styled.container>
  );
}

export default EditorInfoItem;
