import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {mediaQueries} from 'styles/shared';

export const wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2rem;

  @media ${mediaQueries.mobile} {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

export const link = styled(Link)`
  color: initial;
  margin-left: 0.5rem;
`;

export const divider = styled.div`
  margin: 0 0.2rem;

  @media ${mediaQueries.mobile} {
    margin: 0 0.4rem;
  }
`;
