import ReactGA from 'react-ga4';

const eventLoginBasic = () => ReactGA.event('login', {method: 'Basic'});

const eventLoginTelegram = () => ReactGA.event('login', {method: 'Telegram'});

const eventSelectCountry = (country: string) =>
  ReactGA.event('select_content', {content_type: 'country', item_id: country});

const eventSendFeedback = (type: string, query: string) =>
  ReactGA.event({
    action: 'Send Feedback',
    category: query,
    label: type
  });

export default {
  eventLoginBasic,
  eventLoginTelegram,
  eventSelectCountry,
  eventSendFeedback
};
