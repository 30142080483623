import {OverlayTrigger, Tooltip} from 'components/bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import literalsCommon from 'localization/common';
import literalsArticle from 'localization/article';

import uiHelper from 'helpers/uiHelper';
import navigationHelper from 'helpers/navigationHelper';

import AppIcon from 'components/common/AppIcon';

import * as styled from './EditorIconsForArticle.styled';

interface Props {
  article: Article;
  handleEditDocument: (item: Article) => void;
  handleShowContentModal: (item: Article) => void;
  handleShowConfirmation?: (item: Article) => void;
}

function EditorIconsForArticle({article, handleEditDocument, handleShowContentModal, handleShowConfirmation}: Props) {
  const articlePath = navigationHelper.getRelativeUrlPathForArticle(article.urlSlug);
  const articleLink = `[${article.title}](${articlePath})`;

  function onCopy() {
    uiHelper.showMessage(literalsCommon.linkCopied);
  }

  function renderCopyTooltip() {
    const tooltipId = `tooltip-copy-${article.id}`;

    return <Tooltip id={tooltipId}>{literalsCommon.copyLink}</Tooltip>;
  }

  function renderEditDocumentTooltip() {
    const tooltipId = `tooltip-edit-document-${article.id}`;

    return <Tooltip id={tooltipId}>{literalsArticle.editArticle}</Tooltip>;
  }

  function renderEditContentTooltip() {
    const tooltipId = `tooltip-edit-${article.id}`;

    return <Tooltip id={tooltipId}>{literalsCommon.editContent}</Tooltip>;
  }

  function renderRemoveTooltip() {
    const tooltipId = `tooltip-remove-${article.id}`;

    return <Tooltip id={tooltipId}>{literalsArticle.removeArticle}</Tooltip>;
  }

  return (
    <styled.icons>
      <OverlayTrigger placement="right" overlay={renderCopyTooltip()}>
        {({ref, ...triggerHandler}) => (
          <CopyToClipboard text={articleLink} onCopy={onCopy} {...triggerHandler}>
            <styled.icon ref={ref}>
              <AppIcon name="copy" size="lg" />
            </styled.icon>
          </CopyToClipboard>
        )}
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={renderEditDocumentTooltip()}>
        <styled.icon onClick={() => handleEditDocument(article)}>
          <AppIcon name="settings" size="lg" />
        </styled.icon>
      </OverlayTrigger>

      <OverlayTrigger placement="right" overlay={renderEditContentTooltip()}>
        <styled.icon onClick={() => handleShowContentModal(article)}>
          <AppIcon name="edit" size="lg" />
        </styled.icon>
      </OverlayTrigger>

      {handleShowConfirmation && (
        <OverlayTrigger placement="right" overlay={renderRemoveTooltip()}>
          <styled.icon onClick={() => handleShowConfirmation(article)}>
            <AppIcon name="remove" size="lg" />
          </styled.icon>
        </OverlayTrigger>
      )}
    </styled.icons>
  );
}

export default EditorIconsForArticle;
