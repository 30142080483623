import {useMemo} from 'react';
import {isEmpty} from 'lodash';

function useCategoriesLookup(categories: CountryInfoCategory[] | LocationInfoCategory[]) {
  const getCategoriesLookup = (
    categories: CountryInfoCategory[] | LocationInfoCategory[]
  ): Record<string, CountryInfoCategory | LocationInfoCategory> => {
    const categoriesLookup: Record<string, CountryInfoCategory | LocationInfoCategory> = {};

    if (!isEmpty(categories)) {
      for (const category of categories) {
        categoriesLookup[category.code] = category;
      }
    }

    return categoriesLookup;
  };

  const categoriesLookup: Record<string, CountryInfoCategory | LocationInfoCategory> = useMemo(() => {
    return getCategoriesLookup(categories);
  }, [categories]);

  return categoriesLookup;
}

export default useCategoriesLookup;
