import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationNavigation {
  generalRecommendations: string;
  countryInformation: string;
  aboutProject: string;
  article: string;
  locations: string;
  login: string;
  logout: string;
  notFoundPage: string;
}

const literals = new LocalizedStrings({
  ua: {
    generalRecommendations: 'Загальні рекомендації',
    countryInformation: 'Інформація про країни',
    aboutProject: 'Про проект',
    locations: 'Локації',
    article: 'Стаття',
    login: 'Авторизація',
    logout: 'Вийти',
    notFoundPage: 'Сторінку не знайдено'
  },
  ru: {
    generalRecommendations: 'Общие рекомендации',
    countryInformation: 'Информация по странам',
    aboutProject: 'О проекте',
    article: 'Статья',
    locations: 'Локации',
    login: 'Авторизация',
    logout: 'Выйти',
    notFoundPage: 'Страница не найдена'
  }
});

export default helper.init<LocalizationNavigation>(literals);
