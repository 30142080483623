import {CopyToClipboard} from 'react-copy-to-clipboard';
import {OverlayTrigger, Tooltip} from 'components/bootstrap';
import {useLocation} from 'react-router-dom';

import literalsCommon from 'localization/common';

import uiHelper from 'helpers/uiHelper';

import AppIcon, {IconName} from 'components/common/AppIcon';

import {colors} from 'styles/shared';

import * as styled from './AccordionHeader.styled';

interface Props {
  title: string;
  code: string;
  icon?: string;
  isEmptyCategory?: boolean;
}

function AccordionHeader({title, code, icon, isEmptyCategory}: Props) {
  const search = useLocation().search;
  const baseUrl = window.location.href;

  const slug = `code=${code}`;
  const link = search ? `${baseUrl}&${slug}` : `${baseUrl}?${slug}`;

  const className: string | undefined = isEmptyCategory ? 'accordion-header empty-category' : undefined;

  const iconName = (icon as IconName) || 'categoryDefault';

  function onCopy() {
    uiHelper.showMessage(literalsCommon.linkCopied);
  }

  function clickHandler(e) {
    if (!e) return;

    e.preventDefault();
    e.stopPropagation();
  }

  function renderCopyTooltip() {
    const tooltipId = `tooltip-copy-${code}`;

    return <Tooltip id={tooltipId}>{literalsCommon.copyLink}</Tooltip>;
  }

  return (
    <styled.container bsPrefix={className}>
      <OverlayTrigger placement="top" overlay={renderCopyTooltip()}>
        {({ref, ...triggerHandler}) => (
          <CopyToClipboard text={link} onCopy={onCopy} {...triggerHandler}>
            <styled.icon ref={ref} onClick={clickHandler}>
              <AppIcon name={iconName} size="lg" color={colors.icon_secondary} />
            </styled.icon>
          </CopyToClipboard>
        )}
      </OverlayTrigger>

      {title}
    </styled.container>
  );
}

export default AccordionHeader;
