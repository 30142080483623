import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface CountryState {
  list: Country[];
  currentCountry?: Country;
  overviewCategories: Record<string, CountryOverviewCategory>;
  infoCategories: CountryInfoCategory[];
}

// Define the initial state using that type
const initialState: CountryState = {
  list: [],
  overviewCategories: {},
  infoCategories: []
};

export const countrySlice = createSlice({
  name: 'country',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    loadCountries: (state, action: PayloadAction<Country[]>) => {
      state.list = action.payload;
    },
    setCurrentCountry: (state, action: PayloadAction<Country>) => {
      state.currentCountry = action.payload;
    },
    loadOverviewCategories: (state, action: PayloadAction<Record<string, CountryOverviewCategory>>) => {
      state.overviewCategories = action.payload;
    },
    loadInfoCategories: (state, action: PayloadAction<CountryInfoCategory[]>) => {
      state.infoCategories = action.payload;
    }
  }
});

export const {loadCountries, setCurrentCountry, loadOverviewCategories, loadInfoCategories} = countrySlice.actions;

export default countrySlice.reducer;
