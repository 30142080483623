import {loadLocationInfoCategories as getLocationInfoCategories} from 'reducers/locationSlice';

import dataService from 'services/dataService';
import helper from './actionHelper';

export default {
  loadLocationInfoCategories
};

function loadLocationInfoCategories() {
  return helper.dispatchAsyncAction(async dispatch => {
    const categories = await dataService.getLocationInfoCategories();
    dispatch(getLocationInfoCategories(categories));
  }, false);
}
