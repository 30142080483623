import styled from 'styled-components';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid lightgrey;
`;

export const label = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: bold;
`;

export const icon = styled.div`
  display: flex;
  align-items: center;
  width: 2.5rem;
  font-size: 12px;

  & svg {
    margin-right: 1rem;
  }
`;

export const text = styled.div`
  display: flex;
  margin-top: 1rem;
  text-align: left;
`;

export const buttonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;
