import {useState} from 'react';
import {Modal, Button, Row, Col, OverlayTrigger, Tooltip} from 'components/bootstrap';
import Editor from '@monaco-editor/react';

import {confirmAction} from 'reducers/commonSlice';

import {useAppDispatch} from 'hooks';

import {openArticle} from 'reducers/articleSlice';

import literalsCommon from 'localization/common';

import config from 'config';
import uiHelper from 'helpers/uiHelper';

import ReactMarkdownRender from 'components/react_markdown';
import AppIcon from 'components/common/AppIcon';

import * as styled from './EditContentModal.styled';

interface Props {
  visible: boolean;
  content: any;
  handleClose: () => void;
  handleSaveChanges: (content: string) => void;
}

function EditContentModal({visible, content, handleSaveChanges, handleClose}: Props) {
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<any>(content);
  const [isSaved, setIsSaved] = useState(false);

  async function onClose() {
    if (isSaved || content === value) {
      return await handleClose();
    }

    dispatch(
      confirmAction({
        action: async () => {
          await handleClose();
        }
      })
    );
  }

  async function onSave() {
    await handleSaveChanges(value);
    if (!isSaved) setIsSaved(true);
    uiHelper.showMessage(literalsCommon.contentSaved);
  }

  function openLink() {
    dispatch(openArticle(config.articleSlugs.markdown_info));
  }

  function renderHeader() {
    return (
      <styled.toolbar>
        <OverlayTrigger placement="left" overlay={<Tooltip id="help-tooltip">{literalsCommon.help}</Tooltip>}>
          <styled.icon onClick={openLink}>
            <AppIcon name="info" />
          </styled.icon>
        </OverlayTrigger>
      </styled.toolbar>
    );
  }

  function render() {
    return (
      <Modal show={visible} backdrop="static" fullscreen onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{literalsCommon.editContent}</Modal.Title>
          {renderHeader()}
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              <Editor
                height="80vh"
                defaultLanguage="markdown"
                theme="light"
                value={value}
                onChange={value => setValue(value)}
                options={{
                  wordWrap: 'on',
                  lineNumbers: 'off',
                  codeLens: false,
                  minimap: {enabled: false},
                  contextmenu: false,
                  quickSuggestions: false,
                  fontSize: 14,
                  unicodeHighlight: {nonBasicASCII: false, ambiguousCharacters: false}
                }}
              />
            </Col>
            <Col md={6}>
              <ReactMarkdownRender>{value}</ReactMarkdownRender>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {literalsCommon.close}
          </Button>
          <Button variant="primary" onClick={onSave}>
            {literalsCommon.save}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return render();
}

export default EditContentModal;
