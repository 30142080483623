import {useParams} from 'react-router-dom';
import {useEffect, useState, useMemo} from 'react';
import {isEmpty} from 'lodash';

import {useAppSelector} from 'hooks';
import useCategoriesLookup from 'hooks/useCategoriesLookup';
import usePermission from 'hooks/usePermission';

import localizationLocation from 'localization/location';

import dataService from 'services/dataService';

import Loader from 'components/common/Loader';
import AccordionComponent from 'components/common/Accordion';
import {TOGGLER} from 'components/common/Accordion/Accordion';

import LocationInfoItem from './components/content_section/LocationInfoItem';
import LocationHeader from './components/LocationHeader';
import BackLink from './components/BackLink';

import * as styled from './LocationPage.styled';

function LocationPage() {
  const {country, code} = useParams();

  const isEditMode = usePermission(country || '');

  const categoriesVisibility = useAppSelector(state => state.common.categoriesVisibility);
  const countriesList = useAppSelector(state => state.country.list);
  const categories = useAppSelector(state => state.location.locationInfoCategories);

  const [locationName, setLocationName] = useState<string>('');
  const [locationInfoList, setLocationInfoList] = useState<LocationInfo[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const categoriesLookup: Record<string, CountryInfoCategory | LocationInfoCategory> = useCategoriesLookup(categories);
  const categoriesSortOrder = useMemo(() => Object.keys(categoriesLookup), [categoriesLookup]);

  const isValidParameters: boolean = useMemo(() => {
    if (!locationName) return false;

    const selectedCountry = countriesList.find(item => item.code === country);
    if (!selectedCountry?.code) return false;

    return true;
  }, [country, locationName, countriesList]);

  useEffect(() => {
    initData();
  }, [country, isEditMode, categoriesSortOrder]);

  async function initData() {
    if (!country || !code || isEmpty(categoriesSortOrder)) return;

    try {
      setIsLoading(true);

      const location = await dataService.getLocation(code);
      if (location) setLocationName(location.label);

      await loadLocationInfo();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  async function loadLocationInfo() {
    try {
      if (!isLoading) setIsLoading(true);

      let result: LocationInfo[] = [];

      const locationInfoData: LocationInfo[] = await dataService.getLocationInfoList(code);

      if (isEditMode) {
        categoriesSortOrder.forEach((categoryCode: string) => {
          const info = locationInfoData.find(item => item.categoryCode === categoryCode);

          if (info) {
            result.push(info);
          } else {
            result.push({
              countryCode: country || '',
              locationCode: code || '',
              categoryCode,
              categoryLabel: categoriesLookup[categoryCode]?.label,
              categoryIcon: categoriesLookup[categoryCode]?.icon,
              content: '',
              contentId: undefined
            });
          }
        });
      } else {
        result = result.concat(locationInfoData);
      }

      //sort result in predefined categories order
      result.sort((a, b) => {
        return categoriesSortOrder.indexOf(a.categoryCode) - categoriesSortOrder.indexOf(b.categoryCode);
      });

      setLocationInfoList(result);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      setLocationInfoList([]);
    } finally {
      setIsLoading(false);
    }
  }

  function render() {
    if (!country || !code) return null;

    const codes = locationInfoList.map(item => item.categoryCode);

    const selectedCountry = countriesList.find(item => item.code === country);

    return (
      <styled.wrapper>
        <styled.container>
          {!isLoading && <BackLink country={selectedCountry} locationName={locationName} />}

          {isValidParameters && !isLoading && (
            <>
              <LocationHeader country={selectedCountry} locationName={locationName} />

              <AccordionComponent codes={codes} isEditMode={isEditMode}>
                {locationInfoList.map((item: LocationInfo) => {
                  // hide empty categories for regular user
                  if (!isEditMode && !item.content) return null;

                  // hide empty categories for content manager
                  if (!item.content && categoriesVisibility === TOGGLER.HIDE) return null;

                  return (
                    <LocationInfoItem
                      key={item.categoryCode}
                      locationInfo={item}
                      isEditMode={isEditMode}
                      onUpdate={loadLocationInfo}
                    />
                  );
                })}
              </AccordionComponent>
            </>
          )}

          {!isValidParameters && !isLoading && (
            <styled.notFound>{localizationLocation.locationNotFound}</styled.notFound>
          )}

          {isLoading && <Loader />}
        </styled.container>
      </styled.wrapper>
    );
  }

  return render();
}

export default LocationPage;
