import {useMemo} from 'react';
import {isEmpty} from 'lodash';

import {useAppSelector} from 'hooks';
import {selectUserPermissions} from 'reducers/userSlice';

function usePermission(country: string) {
  const permissions = useAppSelector(selectUserPermissions);

  const hasPermissions = useMemo(() => {
    if (!permissions) return false;

    if (isEmpty(permissions?.countries)) return true;

    if (permissions?.countries?.includes(country)) return true;

    return false;
  }, [country, permissions]);

  return hasPermissions;
}

export default usePermission;
