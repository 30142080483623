import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationAuth {
  login: string;
  userName: string;
  password: string;
  userNameRequired: string;
  passwordRequired: string;
}

const literals = new LocalizedStrings({
  ua: {
    login: 'Війти',
    userName: `Ім'я користувача`,
    password: 'Пароль',
    userNameRequired: `Поле ім'я користувача необхідно.`,
    passwordRequired: 'Поле пароль необхідно.'
  },
  ru: {
    login: 'Войти',
    userName: 'Имя пользователя',
    password: 'Пароль',
    userNameRequired: 'Поле имя пользователя необходимо.',
    passwordRequired: 'Поле пароль необходимо.'
  }
});

export default helper.init<LocalizationAuth>(literals);
