import styled from 'styled-components';

import {Navbar, Nav} from 'components/bootstrap';

import {mediaQueries} from 'styles/shared';

export const navbar = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
`;

export const header = styled.span`
  margin-left: 0.8rem;
`;

export const navCollapse = styled(Navbar.Collapse)`
  flex-grow: 0;
`;

export const logoutWrapper = styled(Nav)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  @media ${mediaQueries.tablet} {
    justify-content: initial;
  }
`;

export const username = styled.div`
  font-weight: bold;
  margin-right: 0.5rem;
`;

export const logoutContainer = styled(Nav.Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const logoutText = styled.span`
  margin-right: 0.5rem;
`;
