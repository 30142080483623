import {Form, Modal, Button} from 'components/bootstrap';

import literalsCommon from 'localization/common';
import literalsArticle from 'localization/article';
import {DEFAULT_LANGUAGE} from 'localization/localizationHelper';

const languages: Option[] = [
  {value: DEFAULT_LANGUAGE, label: 'Українська'},
  {value: 'ru', label: 'Русский'},
  {value: 'en', label: 'English'}
];

interface Props {
  visible: boolean;
  articleToEdit: Article;
  handleClose: () => void;
  onSave: (article: Article) => void;
  handleChange: (field: string, value: string) => void;
}

function SaveArticleModal({visible, handleClose, articleToEdit, onSave, handleChange}: Props) {
  const title = articleToEdit.isNote ? literalsArticle.addNote : literalsArticle.addArticle;
  const buttonDisabled = !articleToEdit.title || !articleToEdit.language;

  async function onSaveAction() {
    await onSave(articleToEdit);
  }

  return (
    <Modal show={visible} backdrop="static" size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>{literalsArticle.articleHeader}</Form.Label>
            <Form.Control
              type="text"
              placeholder={literalsArticle.specifyDocumentTitle}
              value={articleToEdit.title}
              onChange={e => handleChange('title', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSelectLanguage">
            <Form.Label>{literalsArticle.articleLanguage}</Form.Label>
            <Form.Select
              aria-label={literalsArticle.specifyDocumentLanguage}
              value={articleToEdit.language}
              onChange={e => handleChange('language', e.target.value)}>
              {!articleToEdit.language && <option>{literalsArticle.specifyDocumentLanguage}</option>}

              {languages.map(option => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {literalsCommon.close}
        </Button>
        <Button variant="primary" disabled={buttonDisabled} onClick={onSaveAction}>
          {literalsCommon.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveArticleModal;
