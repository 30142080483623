import {useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import userActions from 'actions/userActions';
import {useAppSelector, useAppDispatch} from 'hooks';

import literalsCommon from 'localization/common';

import authService from 'services/authService';

import Navigation from 'components/common/Navigation';

interface Props {
  title: string;
  children?: any;
}

function AppPage(props: Props) {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.user.current);

  useEffect(() => {
    if (user) return;

    const token = authService.getToken();
    if (!token) return;

    dispatch(userActions.getCurrentUser());
  }, [user]);

  function getTitle() {
    const projectName = literalsCommon.projectName;
    return props.title ? `${projectName} - ${props.title}` : projectName;
  }

  function render() {
    const title = getTitle();

    return (
      <HelmetProvider>
        <Helmet title={title} />

        <Navigation />

        {props.children}
      </HelmetProvider>
    );
  }

  return render();
}

export default AppPage;
