import styled from 'styled-components';

import {ButtonGroup} from 'components/bootstrap';

import {mediaQueries} from 'styles/shared';

export const toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media ${mediaQueries.mobile} {
    flex-direction: row;
    justify-content: ${props => (props.withHeader ? 'space-between' : 'flex-end')};
  }
`;

export const toolbarButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaQueries.mobile} {
    flex-direction: row;
  }
`;

export const toggler = styled(ButtonGroup)`
  margin-right: 0;
  margin-bottom: 1rem;

  @media ${mediaQueries.mobile} {
    margin-right: 2rem;
    margin-bottom: 0;
  }
`;
