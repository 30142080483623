import {useState, useEffect} from 'react';
import {Modal} from 'components/bootstrap';

import {useAppSelector, useAppDispatch} from 'hooks';
import {openPreviousArticle} from 'reducers/articleSlice';

import literalsCommon from 'localization/common';
import literalsArticle from 'localization/article';

import navigationHelper from 'helpers/navigationHelper';
import uiHelper from 'helpers/uiHelper';

import dataService from 'services/dataService';

import ReactMarkdownRender from 'components/react_markdown';
import Loader from 'components/common/Loader';
import Feedback from 'components/common/Feedback';
import EditorIconsForArticle from 'components/common/EditorIconsForArticle';
import SaveArticleModal from 'components/common/EditorInfo/components/save_article_modal/SaveArticleModal';
import EditContentModal from 'components/common/EditContentModal';

import * as styled from './ArticleModal.styled';

interface Props {
  visible: boolean;
  slug: string;
  handleClose: () => void;
}

function ArticleModal({visible, slug, handleClose}: Props) {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.user.current);
  const articleHistory = useAppSelector(state => state.article.articleHistory);

  const [article, setArticle] = useState<Article>();
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);
  const [contentIdToEdit, setContentIdToEdit] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadArticle();
  }, [slug]);

  async function loadArticle() {
    if (!slug) return;

    setIsLoading(true);

    const response: Article | undefined = await dataService.getArticle(slug);

    setArticle(response);

    setIsLoading(false);
  }

  function openInSeparateTab() {
    const articlePath = navigationHelper.getRelativeUrlPathForArticle(slug);
    window.open(articlePath, '_blank');
  }

  function goBack() {
    dispatch(openPreviousArticle());
  }

  function handleEditDocument(item: Article) {
    setArticleToEdit(item);
  }

  function handleCloseEditModal() {
    setArticleToEdit(null);
  }

  function onArticleChange(field: string, value: string) {
    if (!articleToEdit) return;
    setArticleToEdit({...articleToEdit, [field]: value});
  }

  async function handleSaveArticle(articleData: Article) {
    const articleUpdated: Article | null = await dataService.updateArticle(articleData);

    if (!articleUpdated) return;

    await loadArticle();

    const message = articleData.isNote ? literalsArticle.noteSaved : literalsArticle.articleSaved;
    uiHelper.showMessage(message);
    handleCloseEditModal();
  }

  function handleShowContentModal(item: Article) {
    setContentIdToEdit(item.contentId);
  }

  function handleCloseEditContentModal() {
    setContentIdToEdit(undefined);
  }

  async function handleUpdateContent(contentBody: string) {
    if (!contentIdToEdit) return;

    await dataService.updateContent(contentIdToEdit, contentBody);

    await loadArticle();

    handleCloseEditContentModal();
  }

  function render() {
    const saveArticleModalVisible = articleToEdit ? true : false;
    const editContentModalVisible = contentIdToEdit ? true : false;
    const backButtonVisible = articleHistory.length > 1 && !isLoading;

    return (
      <>
        <Modal show={visible} backdrop="static" fullscreen onHide={handleClose}>
          <styled.header>
            {article && (
              <EditorIconsForArticle
                article={article}
                handleEditDocument={handleEditDocument}
                handleShowContentModal={handleShowContentModal}
              />
            )}

            <styled.buttonsContainer>
              {backButtonVisible && (
                <styled.button variant="warning" onClick={goBack}>
                  {literalsArticle.return}
                </styled.button>
              )}

              <styled.button onClick={openInSeparateTab}>{literalsArticle.openInSeparateTab}</styled.button>

              <styled.button variant="secondary" onClick={handleClose}>
                {literalsCommon.close}
              </styled.button>
            </styled.buttonsContainer>
          </styled.header>

          {article && !isLoading && (
            <Modal.Body>
              <styled.wrapper>
                <styled.title>
                  <ReactMarkdownRender>{article.title}</ReactMarkdownRender>
                </styled.title>
                <ReactMarkdownRender>{article.content}</ReactMarkdownRender>

                {!user && <Feedback type="article" data={{slug}} />}
              </styled.wrapper>
            </Modal.Body>
          )}

          {isLoading && <Loader />}
        </Modal>

        {saveArticleModalVisible && articleToEdit && (
          <SaveArticleModal
            visible={saveArticleModalVisible}
            handleClose={handleCloseEditModal}
            articleToEdit={articleToEdit}
            onSave={handleSaveArticle}
            handleChange={onArticleChange}
          />
        )}

        {editContentModalVisible && (
          <EditContentModal
            visible={editContentModalVisible}
            content={article?.content}
            handleClose={handleCloseEditContentModal}
            handleSaveChanges={handleUpdateContent}
          />
        )}
      </>
    );
  }

  return render();
}

export default ArticleModal;
