import {isEmpty, sortBy} from 'lodash';

import httpHelper from 'helpers/httpHelper';

export default {
  getCountries,
  getCountryOverviewCategories,
  getCountryOverview,
  saveCountryOverview,
  getArticles,
  getArticle,
  addArticle,
  updateArticle,
  removeArticle,
  updateContent,
  getCountryInfoCategories,
  getCountryInfo,
  addCountryInfoRecord,
  getGeneralInfo,
  getCountryLocations,
  getLocation,
  removeLocation,
  saveLocation,
  getLocationInfoCategories,
  getLocationInfoList,
  addLocationInfoRecord,
  sendFeedback
};

// country APIs

async function getCountries(): Promise<Country[]> {
  try {
    return await httpHelper.get('/api/countries', {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

// country overview APIs

async function getCountryOverviewCategories(): Promise<Record<string, CountryOverviewCategory>> {
  const countryOverviewCategoriesLookup: Record<string, CountryOverviewCategory> = {};

  try {
    const data = await httpHelper.get('/api/country-overview-categories', {});

    if (isEmpty(data)) return countryOverviewCategoriesLookup;

    data.forEach(category => {
      const code = category.code;

      countryOverviewCategoriesLookup[code] = {
        label: category.label,
        code,
        icon: code,
        description: category.description,
        sortOrder: category.sortOrder
      };
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    return countryOverviewCategoriesLookup;
  }
}

async function getCountryOverview(
  countryCode: string,
  countryOverviewCategoriesLookup: Record<string, CountryOverviewCategory>
): Promise<CountryOverviewItem[]> {
  const countryOverview: CountryOverviewItem[] = [];

  try {
    const data = await httpHelper.get(`/api/country-overviews/${countryCode}`, {});

    if (isEmpty(data)) return countryOverview;

    for (const item of data) {
      countryOverview.push({
        category: countryOverviewCategoriesLookup[item.categoryCode],
        text: item.text
      });
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    return sortBy(countryOverview, 'category.sortOrder');
  }
}

async function saveCountryOverview(countryCode: string, categoryCode: string, text: string): Promise<number | null> {
  try {
    const body = {
      countryCode,
      categoryCode,
      text
    };

    const id = await httpHelper.post('/api/country-overview', body);
    return id;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
}

// article APIs

async function getArticles(countryCode?: string): Promise<Article[]> {
  try {
    const articles = await httpHelper.get('/api/articles', {countryCode: countryCode || ''});
    return articles;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function getArticle(slug: string): Promise<Article | undefined> {
  try {
    const article = await httpHelper.get(`/api/article/${slug}`, {});
    return article;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return undefined;
  }
}

async function addArticle(articleData: Article): Promise<number | null> {
  try {
    const contentId = await httpHelper.post('/api/article', articleData);
    return contentId;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
}

async function updateArticle(articleData: Article): Promise<Article | null> {
  try {
    const body = {
      id: articleData.id,
      title: articleData.title,
      language: articleData.language
    };

    return await httpHelper.put('/api/article', body);
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
}

async function removeArticle(id: number, contentId?: number): Promise<boolean> {
  try {
    await httpHelper.delete(`/api/article/${id}`);

    if (contentId) await httpHelper.delete(`/api/content/${contentId}`);

    return true;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return false;
  }
}

// content APIs

async function updateContent(contentId: number, text: string): Promise<void> {
  try {
    const body = {
      id: contentId,
      body: text
    };

    return await httpHelper.put('/api/content', body);
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
}

// country info APIs

async function getCountryInfoCategories(): Promise<CountryInfoCategory[]> {
  try {
    return await httpHelper.get('/api/country-info-categories', {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function getCountryInfo(countryCode: string): Promise<CountryInfo[]> {
  try {
    return await httpHelper.get(`/api/country-info/${countryCode}`, {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function addCountryInfoRecord(countryCode: string, categoryCode: string): Promise<number | null> {
  try {
    const body = {
      countryCode,
      categoryCode
    };

    const contentId = await httpHelper.post('/api/country-info', body);
    return contentId;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
}

// general info APIs

async function getGeneralInfo(): Promise<GeneralInfo[]> {
  try {
    return await httpHelper.get('/api/general-info', {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

// location APIs

async function getCountryLocations(countryCode: string): Promise<LocationItem[]> {
  try {
    return await httpHelper.get(`/api/locations/${countryCode}`, {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function getLocation(locationCode: string): Promise<LocationItem | undefined> {
  try {
    return await httpHelper.get(`/api/location/${locationCode}`, {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
}

async function removeLocation(code: string): Promise<boolean> {
  try {
    await httpHelper.delete(`/api/location/${code}`);

    return true;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return false;
  }
}

async function saveLocation(locationData: LocationItem): Promise<boolean> {
  try {
    await httpHelper.post('/api/location', {...locationData});

    return true;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return false;
  }
}

async function getLocationInfoCategories(): Promise<LocationInfoCategory[]> {
  try {
    return await httpHelper.get('/api/location-info-categories', {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function getLocationInfoList(locationCode?: string): Promise<LocationInfo[]> {
  if (!locationCode) return [];

  try {
    return await httpHelper.get(`/api/location-info/${locationCode}`, {});
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return [];
  }
}

async function addLocationInfoRecord(locationCode: string, categoryCode: string): Promise<number | null> {
  try {
    const body = {
      locationCode,
      categoryCode
    };

    const contentId = await httpHelper.post('/api/location-info', body);
    return contentId;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
}

// feedback APIs

async function sendFeedback(feedbackData: FeedbackDto): Promise<boolean> {
  try {
    await httpHelper.post('/api/feedback', feedbackData);

    return true;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return false;
  }
}
