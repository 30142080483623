import styled from 'styled-components';

import {mediaQueries} from 'styles/shared';

export const title = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  & img {
    margin-right: 1rem;
  }

  @media ${mediaQueries.desktop} {
    display: flex;
    margin-bottom: 0;
  }
`;
