import styled from 'styled-components';

import Container from 'components/common/Container';
import Loader from 'components/common/Loader';

export const wrapper = styled(Container)`
  width: 100%;
`;

export const loader = styled(Loader)`
  margin-top: 200px;
`;
