import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface LocationState {
  locationInfoCategories: LocationInfoCategory[];
}

const initialState: LocationState = {
  locationInfoCategories: []
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    loadLocationInfoCategories: (state, action: PayloadAction<LocationInfoCategory[]>) => {
      state.locationInfoCategories = action.payload;
    }
  }
});

export const {loadLocationInfoCategories} = locationSlice.actions;

export default locationSlice.reducer;
