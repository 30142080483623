import styled from 'styled-components';

import {mediaQueries} from 'styles/shared';

import Container from 'components/common/Container';

export const wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5rem 2rem;

  @media ${mediaQueries.mobile} {
    width: 60%;
    padding: 0 2rem 2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 2rem;

  & img {
    height: fit-content;
    margin-right: 0.8rem;
  }

  @media ${mediaQueries.mobile} {
    margin-bottom: 3rem;
  }
`;
