import AppIcon, {IconName} from 'components/common/AppIcon';

import * as styled from './OverviewItem.styled';

interface Props {
  item: CountryOverviewItem;
}

function OverviewItem({item}: Props) {
  const category = item.category;
  const icon = category.icon as IconName;

  return (
    <styled.container>
      <styled.label>
        <styled.icon>
          <AppIcon name={icon} />
        </styled.icon>

        <span>{category.label}</span>
      </styled.label>

      <styled.text>{item.text}</styled.text>
    </styled.container>
  );
}

export default OverviewItem;
