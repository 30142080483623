import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';

import {useAppSelector} from 'hooks';

import dataService from 'services/dataService';

import ReactMarkdownRender from 'components/react_markdown';
import Feedback from 'components/common/Feedback';

import * as styled from './ArticlePage.styled';

function ArticlePage() {
  const {slug} = useParams();

  const user = useAppSelector(state => state.user.current);

  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    loadArticle();
  }, [slug]);

  async function loadArticle() {
    if (!slug) return;

    const response: Article | undefined = await dataService.getArticle(slug);
    setArticle(response);
  }

  if (!article) return null;

  return (
    <styled.wrapper>
      <styled.title>
        <ReactMarkdownRender>{article.title}</ReactMarkdownRender>
      </styled.title>
      <ReactMarkdownRender>{article.content}</ReactMarkdownRender>

      {!user && <Feedback type="article" data={{slug}} />}
    </styled.wrapper>
  );
}

export default ArticlePage;
