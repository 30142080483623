import {useState} from 'react';
import {Modal, Button} from 'components/bootstrap';

import {confirmAction} from 'reducers/commonSlice';

import {useAppDispatch} from 'hooks';

import literalsCommon from 'localization/common';
import literalsCountryInfo from 'localization/countryInfo';

import validationHelper from 'helpers/validationHelper';

import TextInput from 'components/common/TextInput';
import TextAreaInput from 'components/common/TextAreaInput';

interface Props {
  visible: boolean;
  feedbackAction: (comment: string, email: string, telegram: string) => void;
  close: () => void;
}

function FeedbackModal({visible, close, feedbackAction}: Props) {
  const dispatch = useAppDispatch();

  const [comment, setComment] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telegram, setTelegram] = useState<string>('');
  const [errors, setErrors] = useState({comment: '', email: ''});

  function formIsValid() {
    const errors = {
      comment: '',
      email: ''
    };

    if (!comment) {
      errors.comment = '';
    }

    if (email && !validationHelper.isValidEmail(email)) {
      errors.email = '';
    }

    setErrors(errors);

    return validationHelper.isEmptyErrorObject(errors);
  }

  function handleSend(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    feedbackAction(comment, email, telegram);
  }

  function handleClose() {
    if (!comment) return close();

    dispatch(
      confirmAction({
        action: async () => {
          await close();
        }
      })
    );
  }

  return (
    <Modal show={visible} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <h4>{literalsCommon.feedback}</h4>
      </Modal.Header>
      <Modal.Body>
        <TextAreaInput
          name="comment"
          label={literalsCountryInfo.comment}
          value={comment}
          rows={5}
          placeholder={literalsCountryInfo.comment}
          onChange={(field, value) => setComment(value)}
          error={errors.comment}
        />

        <h5>{literalsCountryInfo.contactsForFeedback}</h5>

        <br />

        <TextInput
          name="email"
          value={email}
          placeholder={literalsCountryInfo.email}
          error={errors.email}
          onChange={(field, value) => setEmail(value)}
        />

        <TextInput
          name="telegram"
          value={telegram}
          placeholder={literalsCountryInfo.telegram}
          onChange={(field, value) => setTelegram(value)}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {literalsCommon.close}
        </Button>
        <Button variant="primary" disabled={!comment} onClick={handleSend}>
          {literalsCommon.send}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FeedbackModal;
