import {useState, useEffect, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {isEmpty} from 'lodash';

import countryActions from 'actions/countryActions';
import {selectGuestUser} from 'reducers/userSlice';

import {useAppSelector, useAppDispatch} from 'hooks';
import usePermission from 'hooks/usePermission';
import useCategoriesLookup from 'hooks/useCategoriesLookup';

import literalsCountryInfo from 'localization/countryInfo';

import config from 'config';

import gaEvents from 'events/gaEvents';

import storageService from 'services/storageService';
import dataService from 'services/dataService';

import EditorInfo from 'components/common/EditorInfo';
import Loader from 'components/common/Loader';
import TitleWithFlag from 'components/common/TitleWithFlag';
import {TOGGLER} from 'components/common/Accordion/Accordion';
import AccordionComponent from 'components/common/Accordion';

import ContentSection from './components/content_section/ContentSection';
import RightPanel from './components/right_panel/RightPanel';

import * as styled from './CountryInfoPage.styled';

function CountryInfoPage() {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const categoriesVisibility = useAppSelector(state => state.common.categoriesVisibility);
  const countriesList = useAppSelector(state => state.country.list);
  const countryOverviewCategoriesLookup = useAppSelector(state => state.country.overviewCategories);
  const categories = useAppSelector(state => state.country.infoCategories);

  const categoriesLookup: Record<string, CountryInfoCategory | LocationInfoCategory> = useCategoriesLookup(categories);

  const isGuestUser = useAppSelector(selectGuestUser);

  const countrySelected = useAppSelector(state => state.country.currentCountry?.code || '');

  const [countryInfo, setCountryInfo] = useState<CountryInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const categoriesSortOrder = useMemo(() => Object.keys(categoriesLookup), [categoriesLookup]);

  const isEditMode = usePermission(countrySelected);

  useEffect(() => {
    // load countries list
    if (!isEmpty(countriesList) && !isEmpty(countryOverviewCategoriesLookup) && !isEmpty(categories)) {
      initData();
    }
  }, [countriesList, countryOverviewCategoriesLookup, categories]);

  async function initData() {
    setIsLoading(true);

    // load preselected country
    const countryCodeParameter = searchParams.get('countryCode');
    const preselectedCountry = storageService.getCountry();
    const preselectedCountryCode = countryCodeParameter || preselectedCountry || '';

    if (!isEmpty(countriesList) && !isEmpty(preselectedCountryCode)) {
      const country = countriesList.find((country: Country) => country.code === preselectedCountryCode);

      if (country && country.code !== countrySelected) dispatch(countryActions.saveCurrentCountry(country));
    }

    if (!preselectedCountryCode) setIsLoading(false);
  }

  const loadCountryInfo = useCallback(async () => {
    try {
      if (!isLoading) setIsLoading(true);

      let result: CountryInfo[] = [];

      const countryInfoData: CountryInfo[] = await dataService.getCountryInfo(countrySelected);

      if (isEditMode) {
        categoriesSortOrder.forEach((categoryCode: string) => {
          const info = countryInfoData.find(item => item.categoryCode === categoryCode);

          if (info) {
            result.push(info);
          } else {
            result.push({
              countryCode: countrySelected,
              publishScope: 'basic',
              categoryCode,
              categoryLabel: categoriesLookup[categoryCode]?.label,
              categoryIcon: categoriesLookup[categoryCode]?.icon,
              content: '',
              contentId: undefined
            });
          }
        });
      } else {
        result = result.concat(countryInfoData);
      }

      //sort result in predefined categories order
      result.sort((a, b) => {
        return categoriesSortOrder.indexOf(a.categoryCode) - categoriesSortOrder.indexOf(b.categoryCode);
      });

      setCountryInfo(result);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      setCountryInfo([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [countrySelected, categoriesLookup, isEditMode, categoriesSortOrder]);

  useEffect(() => {
    if (!countrySelected) return;

    searchParams.set('countryCode', countrySelected);
    setSearchParams(searchParams);

    loadCountryInfo();
  }, [countrySelected]);

  function selectCountry(countryCode: string) {
    const country = countriesList.find((country: Country) => country.code === countryCode);

    if (country) {
      gaEvents.eventSelectCountry(countryCode);
      storageService.saveCountry(countryCode);
      dispatch(countryActions.saveCurrentCountry(country));

      //clear state
      setCountryInfo([]);
    }
  }

  function renderEmptyState() {
    return (
      <styled.description>
        {literalsCountryInfo.noCountryInfo} <br /> {literalsCountryInfo.shareInformation}{' '}
        <a href={config.telegramBot} target="_blank" rel="noreferrer">
          {literalsCountryInfo.telegramBot}
        </a>
      </styled.description>
    );
  }

  function render() {
    const country = countriesList.find((country: Country) => country.code === countrySelected);
    const title = country?.label || '';
    const isFullPublishScope = country?.publishScope === 'full';

    let editorInfoVisible = isEditMode && countrySelected && !isLoading;
    if (isGuestUser && isFullPublishScope) editorInfoVisible = false;

    const codes = countryInfo.map(item => item.categoryCode);

    return (
      <styled.wrapper>
        <styled.container>
          <styled.content>
            {!countrySelected && !isLoading && (
              <styled.description>{literalsCountryInfo.pleaseSelectCountry}</styled.description>
            )}

            {countrySelected && !isEmpty(countryInfo) && !isLoading && (
              <>
                <TitleWithFlag title={title} country={countrySelected} hideOnMobile={true} />

                <AccordionComponent codes={codes} isEditMode={isEditMode}>
                  {countryInfo.map((item: CountryInfo) => {
                    // hide empty categories for regular user
                    if (!isEditMode && !item.content) return null;

                    // hide empty categories for content manager
                    if (!item.content && categoriesVisibility === TOGGLER.HIDE) return null;

                    return (
                      <ContentSection
                        key={item.categoryCode}
                        countryInfo={item}
                        isGuestUser={isGuestUser}
                        isEditMode={isEditMode}
                        onUpdate={() => loadCountryInfo()}
                      />
                    );
                  })}
                </AccordionComponent>
              </>
            )}

            {countrySelected && isEmpty(countryInfo) && !isLoading && renderEmptyState()}

            {editorInfoVisible && <EditorInfo countryCode={countrySelected} />}

            {isLoading && <Loader />}
          </styled.content>

          <RightPanel
            countryCode={countrySelected}
            countriesList={countriesList}
            selectCountry={selectCountry}
            countryOverviewCategoriesLookup={countryOverviewCategoriesLookup}
            isEditMode={isEditMode}
          />
        </styled.container>
      </styled.wrapper>
    );
  }

  return render();
}

export default CountryInfoPage;
