import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';
import {last} from 'lodash';

interface ArticleState {
  articleHistory: string[];
}

const initialState: ArticleState = {
  articleHistory: []
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    openArticle: (state, action: PayloadAction<string>) => {
      state.articleHistory.push(action.payload);
    },
    closeArticle: state => {
      state.articleHistory = [];
    },
    openPreviousArticle: state => {
      state.articleHistory = state.articleHistory.slice(0, -1);
    }
  }
});

export const {openArticle, closeArticle, openPreviousArticle} = articleSlice.actions;

export default articleSlice.reducer;

export const selectCurrentArticle = (state: RootState) => last(state.article.articleHistory);
