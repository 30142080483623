import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationLocation {
  locations: string;
  addNewLocation: string;
  editLocation: string;
  removeLocation: string;
  locationRemoved: string;
  locationCode: string;
  locationCodePlaceholder: string;
  locationLabel: string;
  locationLabelPlaceholder: string;
  locationCreated: string;
  locationUpdated: string;
  locationNotFound: string;
}

const literals = new LocalizedStrings({
  ua: {
    locations: 'Локації',
    addNewLocation: 'Додати нову локацію',
    editLocation: 'Редагувати локацію',
    removeLocation: 'Видалити локацію',
    locationRemoved: 'Локацію успішно видалено',
    locationCode: 'Код локації',
    locationCodePlaceholder: 'Код локації (phuket)',
    locationLabel: 'Назва локації',
    locationLabelPlaceholder: 'Назва локації (Пхукет)',
    locationCreated: 'Локація успішно створено!',
    locationUpdated: 'Локацію успішно оновлено',
    locationNotFound: 'Вказану локацію не знайдено...'
  },
  ru: {
    locations: 'Локации',
    editLocation: 'Редактировать локацию',
    addNewLocation: 'Добавить новую локацию',
    removeLocation: 'Удалить локацию',
    locationRemoved: 'Локация успешно удалена',
    locationCode: 'Код локации',
    locationCodePlaceholder: 'Код локации (phuket)',
    locationLabel: 'Название лоакции',
    locationLabelPlaceholder: 'Название лоакции (Пхукет)',
    locationCreated: 'Локация успешно создана!',
    locationUpdated: 'Локацию успешно обновлена!',
    locationNotFound: 'Указанная локация не найдена...'
  }
});

export default helper.init<LocalizationLocation>(literals);
