import {useState} from 'react';
import {Button} from 'components/bootstrap';

import literalsCommon from 'localization/common';

import dataService from 'services/dataService';

import EditContentModal from 'components/common/EditContentModal';

import * as styled from './EditContentAction.styled';

interface Props {
  contentId?: number;
  content: string;
  handleNewContentCreation: any;
  onUpdate: () => void;
}

function EditContentAction({contentId, content, handleNewContentCreation, onUpdate}: Props) {
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  function handleShow() {
    setEditModalVisible(true);
  }

  function handleClose() {
    onUpdate();
    setEditModalVisible(false);
  }

  async function handleSaveChanges(contentBody: string) {
    if (!contentId) {
      contentId = await handleNewContentCreation();
    }
    if (contentId) {
      await dataService.updateContent(contentId, contentBody);
    }
  }

  return (
    <>
      {contentId && (
        <styled.wrapper>
          <Button onClick={handleShow}>{literalsCommon.edit}</Button>
        </styled.wrapper>
      )}

      {!contentId && (
        <styled.wrapper>
          <Button onClick={handleShow} variant="success">
            {literalsCommon.addNewRecord}
          </Button>
        </styled.wrapper>
      )}

      {editModalVisible && (
        <EditContentModal
          visible={editModalVisible}
          content={content}
          handleClose={handleClose}
          handleSaveChanges={handleSaveChanges}
        />
      )}
    </>
  );
}

export default EditContentAction;
