import {useState, useEffect} from 'react';

import countryActions from 'actions/countryActions';
import {useAppDispatch} from 'hooks';

import literalsCommon from 'localization/common';
import literalsCountryInfo from 'localization/countryInfo';

import FEATURE from 'constants/features';

import config from 'config';
import uiHelper from 'helpers/uiHelper';

import gaEvents from 'events/gaEvents';

import FeedbackModal from './components/FeedbackModal';

import * as styled from './Feedback.styled';

interface FeedbackProps {
  slug?: string;
  code?: string;
  country?: string;
  location?: string;
}

type feedbackType = 'article' | 'general_info' | 'country_info' | 'location_info';

interface Props {
  type: feedbackType;
  data: FeedbackProps;
}

function Feedback({type, data}: Props) {
  const dispatch = useAppDispatch();

  const {slug, code, country, location} = data;

  const [queryString, setQueryString] = useState<string>('');
  const [feedbackModalVisible, setFeedbackModalVisible] = useState<boolean>(false);

  useEffect(() => {
    let queryStr: string = `${type}-`;

    switch (type) {
      case 'article':
        queryStr += slug;
        break;

      case 'general_info':
        queryStr += code;
        break;

      case 'country_info':
        queryStr += `${code}-${country}`;
        break;

      case 'location_info':
        queryStr += `${code}-${country}-${location}`;
        break;

      default:
        break;
    }

    setQueryString(queryStr);
  }, [slug, code, country]);

  function validate() {
    const validateUrl = `${config.telegramBot}?start=${queryString}-validate`;
    window.open(validateUrl, '_blank');
  }

  function feedbackAction() {
    if (FEATURE.FEEDBACK_LIKE_A_FORM) {
      toggleFeedbackModal();
      return;
    }

    const feedbackUrl = `${config.telegramBot}?start=${queryString}-feedback`;
    gaEvents.eventSendFeedback(type, queryString);
    window.open(feedbackUrl, '_blank');
  }

  async function sendFeedback(comment: string, email: string, telegram: string) {
    const feedbackData = {
      queryString,
      comment,
      email,
      telegram
    };

    const isSuccess: boolean = await dispatch(countryActions.sendFeedback(feedbackData));

    if (isSuccess) {
      toggleFeedbackModal();
      uiHelper.showMessage(literalsCommon.feedbackSuccessfullySend);
    }
  }

  function toggleFeedbackModal() {
    setFeedbackModalVisible(visible => !visible);
  }

  return (
    <styled.container>
      {FEATURE.FEEDBACK_CONFIRMATION_VISIBLE && (
        <styled.button variant="success" onClick={validate}>
          {literalsCountryInfo.everythingRight}
        </styled.button>
      )}

      <styled.button variant="warning" onClick={feedbackAction}>
        {literalsCountryInfo.anyRemarks}
      </styled.button>

      {feedbackModalVisible && (
        <FeedbackModal visible={feedbackModalVisible} feedbackAction={sendFeedback} close={toggleFeedbackModal} />
      )}
    </styled.container>
  );
}

export default Feedback;
