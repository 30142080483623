import {Accordion} from 'components/bootstrap';

import dataService from 'services/dataService';

import Feedback from 'components/common/Feedback';
import ReactMarkdownRender from 'components/react_markdown';
import EditContentAction from 'components/common/EditContentAction';
import AccordionHeader from 'components/common/Accordion/header/AccordionHeader';

const BASIC_COUNTRY_INFO_CATEGORIES = ['resources', 'entry_requirements'];

interface Props {
  countryInfo: CountryInfo;
  isEditMode: boolean;
  isGuestUser: boolean;
  onUpdate: () => void;
}

function ContentSection({countryInfo, isEditMode, isGuestUser, onUpdate}: Props) {
  const {countryCode, publishScope, categoryCode, categoryLabel, categoryIcon, contentId, content} = countryInfo;

  const isEmptyCategory = content ? false : true;

  async function handleAddNewEntry() {
    return await dataService.addCountryInfoRecord(countryCode, categoryCode);
  }

  function renderActionButtons() {
    let editorMode = isEditMode;

    if (isGuestUser) {
      if (publishScope === 'full') editorMode = false;

      const isBasicCategory = BASIC_COUNTRY_INFO_CATEGORIES.includes(categoryCode);
      if (publishScope === 'basic' && isBasicCategory) editorMode = false;
    }

    if (!editorMode) return <Feedback type="country_info" data={{code: categoryCode, country: countryCode}} />;

    return (
      <EditContentAction
        contentId={contentId}
        content={content}
        handleNewContentCreation={handleAddNewEntry}
        onUpdate={onUpdate}
      />
    );
  }

  function render() {
    return (
      <Accordion.Item eventKey={categoryCode}>
        <AccordionHeader
          title={categoryLabel}
          code={categoryCode}
          icon={categoryIcon}
          isEmptyCategory={isEmptyCategory}
        />

        <Accordion.Body>
          {!isEmptyCategory && <ReactMarkdownRender>{content}</ReactMarkdownRender>}

          {renderActionButtons()}
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  return render();
}

export default ContentSection;
