import {CircleFlag} from 'react-circle-flags';

import navigationHelper from 'helpers/navigationHelper';

import * as styled from './LocationHeader.styled';

interface Props {
  country?: Country;
  locationName: string;
}

function BackLink({country, locationName}: Props) {
  if (!country) return null;

  const slug = navigationHelper.getRelativeUrlPathForInfo(country.code);

  return (
    <styled.wrapper>
      <div>
        <CircleFlag countryCode={country.code} height="50" />
        <styled.link to={slug}>{country.label}</styled.link>
      </div>
      <styled.divider>/</styled.divider>
      <div>{locationName}</div>
    </styled.wrapper>
  );
}

export default BackLink;
