import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationArticle {
  noteSaved: string;
  articleSaved: string;
  articleRemoved: string;
  forEditor: string;
  articles: string;
  notes: string;
  addArticle: string;
  addNote: string;
  articleHeader: string;
  specifyDocumentTitle: string;
  articleLanguage: string;
  specifyDocumentLanguage: string;
  editArticle: string;
  removeArticle: string;
  return: string;
  openInSeparateTab: string;
}

const literals = new LocalizedStrings({
  ua: {
    noteSaved: 'Замітка збережена. Будь ласка, додайте контент.',
    articleSaved: 'Статтю збережено. Будь ласка, додайте контент.',
    articleRemoved: 'Документ успішно видалено.',
    forEditor: 'Для редактора',
    articles: 'Статті',
    notes: 'Замітки',
    addArticle: 'Додати статтю',
    addNote: 'Додати замітку',
    articleHeader: 'Заголовок',
    specifyDocumentTitle: 'Вкажіть заголовок документа',
    articleLanguage: 'Мова',
    specifyDocumentLanguage: 'Вкажіть мову документа',
    editArticle: 'Редагувати документ',
    removeArticle: 'Видалити документ',
    return: 'Повернутися',
    openInSeparateTab: 'Відкрити в окремій вкладці'
  },
  ru: {
    noteSaved: 'Заметка сохранена. Пожалуйста, добавьте контент.',
    articleSaved: 'Статья сохранена. Пожалуйста, добавьте контент.',
    articleRemoved: 'Документ был успешно удалён.',
    forEditor: 'Для редактора',
    articles: 'Статьи',
    notes: 'Заметки',
    addArticle: 'Добавить статью',
    addNote: 'Добавить заметку',
    articleHeader: 'Заголовок',
    specifyDocumentTitle: 'Укажите заголовок документа',
    articleLanguage: 'Язык',
    specifyDocumentLanguage: 'Укажите язык документа',
    editArticle: 'Редактировать документ',
    removeArticle: 'Удалить документ',
    return: 'Вернуться назад',
    openInSeparateTab: 'Открыть в новой вкладке'
  }
});

export default helper.init<LocalizationArticle>(literals);
