import styled from 'styled-components';

import CountrySelect from 'components/common/CountrySelect';

import {mediaQueries, rightPanelWidth, headerHeight} from 'styles/shared';

export const wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  @media ${mediaQueries.desktop} {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: ${rightPanelWidth};
    min-width: ${rightPanelWidth};
    background-color: #f8f8ff;
    overflow-y: auto;
  }
`;

export const countrySelect = styled(CountrySelect)`
  width: 20rem;
  margin-bottom: 2rem;

  & button {
    background: white;
  }

  @media ${mediaQueries.desktop} {
    margin-top: ${headerHeight};
    margin-bottom: 3rem;
  }
`;

export const buttonContainer = styled.div`
  margin-top: ${props => (props.withMargin ? '2rem' : 0)};
`;
