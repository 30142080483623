import {useState, useEffect, useCallback} from 'react';
import {OverlayTrigger, Tooltip, Col} from 'components/bootstrap';
import {isEmpty} from 'lodash';
import {Link} from 'react-router-dom';

import uiHelper from 'helpers/uiHelper';

import dataService from 'services/dataService';

import literalsLocation from 'localization/location';

import AppIcon from 'components/common/AppIcon';
import Confirmation from 'components/common/Confirmation';

import LocationModal from './location_modal/LocationModal';

import * as styled from './Locations.styled';

interface Props {
  isEditMode: boolean;
  countryCode: string;
}

function Locations({isEditMode, countryCode}: Props) {
  const [locations, setLocations] = useState<LocationItem[]>([]);

  const [locationToEdit, setLocationToEdit] = useState<LocationItem | undefined>();
  const [locationToRemove, setLocationToRemove] = useState<string | null>(null);

  const loadLocations = useCallback(async () => {
    if (!countryCode) {
      setLocations([]);
      return;
    }

    const locationsList: LocationItem[] = await dataService.getCountryLocations(countryCode);
    setLocations(locationsList);
  }, [countryCode]);

  useEffect(() => {
    loadLocations();
  }, [countryCode, loadLocations]);

  function handleAddLocation() {
    const locationToAdd: LocationItem = {
      code: '',
      label: '',
      country: countryCode
    };

    setLocationToEdit(locationToAdd);
  }

  function handleEditLocation(location: LocationItem) {
    setLocationToEdit(location);
  }

  function handleCloseLocationModal() {
    setLocationToEdit(undefined);
  }

  function handleChangeLocation(field: string, value: string) {
    if (!locationToEdit) return;

    setLocationToEdit({...locationToEdit, [field]: value});
  }

  async function handleSaveLocation() {
    if (!locationToEdit) return;

    const isSuccess = await dataService.saveLocation(locationToEdit);

    if (isSuccess) {
      await loadLocations();

      const message = locationToEdit?.id ? literalsLocation.locationUpdated : literalsLocation.locationCreated;
      uiHelper.showMessage(message);

      handleCloseLocationModal();
    }
  }

  function handleShowConfirmation(location: LocationItem) {
    setLocationToRemove(location.code);
  }

  function handleCloseConfirmation() {
    setLocationToRemove(null);
  }

  async function handleRemove() {
    if (!locationToRemove) return;

    const isSuccess = await dataService.removeLocation(locationToRemove);

    if (isSuccess) {
      await loadLocations();
      uiHelper.showMessage(literalsLocation.locationRemoved);
    }

    handleCloseConfirmation();
  }

  function renderEditLocationTooltip(id) {
    const tooltipId = `tooltip-edit-location-${id}`;

    return <Tooltip id={tooltipId}>{literalsLocation.editLocation}</Tooltip>;
  }

  function renderRemoveTooltip(id) {
    const tooltipId = `tooltip-remove-location-${id}`;

    return <Tooltip id={tooltipId}>{literalsLocation.removeLocation}</Tooltip>;
  }

  function renderLocation(location: LocationItem) {
    const code = location.code;

    const slug = `/location/${countryCode}/${code}`;

    return (
      <styled.locationRow key={code}>
        <Link to={slug}>{location.label}</Link>

        {isEditMode && (
          <styled.icons>
            <OverlayTrigger placement="left" overlay={renderEditLocationTooltip(location.id)}>
              <styled.icon onClick={() => handleEditLocation(location)}>
                <AppIcon name="edit" size="lg" />
              </styled.icon>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={renderRemoveTooltip(location.id)}>
              <styled.icon onClick={() => handleShowConfirmation(location)}>
                <AppIcon name="remove" size="lg" />
              </styled.icon>
            </OverlayTrigger>
          </styled.icons>
        )}
      </styled.locationRow>
    );
  }

  function render() {
    if (isEmpty(locations) && !isEditMode) return null;

    const locationModalVisible = locationToEdit ? true : false;
    const confirmationVisible = locationToRemove ? true : false;

    return (
      <styled.wrapper>
        <styled.header>
          <Col xs={8}>
            <styled.title>{literalsLocation.locations}</styled.title>
          </Col>
          {isEditMode && (
            <Col xs={4}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip-add-location">{literalsLocation.addNewLocation}</Tooltip>}>
                <styled.addIcon onClick={handleAddLocation}>
                  <AppIcon name="add" color="green" />
                </styled.addIcon>
              </OverlayTrigger>
            </Col>
          )}
        </styled.header>

        {locations.map(location => {
          return renderLocation(location);
        })}

        {locationModalVisible && locationToEdit && (
          <LocationModal
            visible={locationModalVisible}
            location={locationToEdit}
            handleChangeLocation={handleChangeLocation}
            handleSaveLocation={handleSaveLocation}
            handleClose={handleCloseLocationModal}
          />
        )}

        {confirmationVisible && (
          <Confirmation
            visible={confirmationVisible}
            handleClose={handleCloseConfirmation}
            handleProceed={handleRemove}
          />
        )}
      </styled.wrapper>
    );
  }

  return render();
}

export default Locations;
