import Cookies from 'js-cookie';

const KEYS = {
  COUNTRY: 'country',
  EMPTY_CATEGORIES_VISIBLE: 'emptyCategoriesVisible'
};

export default {
  getCountry,
  saveCountry,
  emptyCategoriesVisible,
  toggleEmptyCategoriesVisibility
};

function getCountry() {
  const country = Cookies.get(KEYS.COUNTRY);
  return country;
}

function saveCountry(country?: string) {
  if (!country) return Cookies.remove(KEYS.COUNTRY);
  Cookies.set(KEYS.COUNTRY, country);
}

function emptyCategoriesVisible() {
  const value = Cookies.get(KEYS.EMPTY_CATEGORIES_VISIBLE);
  return value === 'true';
}

function toggleEmptyCategoriesVisibility() {
  const value = emptyCategoriesVisible();

  if (value) {
    Cookies.remove(KEYS.EMPTY_CATEGORIES_VISIBLE);
    return false;
  }

  Cookies.set(KEYS.EMPTY_CATEGORIES_VISIBLE, 'true');
  return true;
}
