import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

const CODE_PARAMETER = 'code';

function useCodeParameter(setActiveKey) {
  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get(CODE_PARAMETER);

  useEffect(() => {
    if (!code) return;

    setActiveKey([code]);
    searchParams.delete(CODE_PARAMETER);
    setSearchParams(searchParams);
  }, [code]);

  return code;
}

export default useCodeParameter;
