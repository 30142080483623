import {CircleFlag} from 'react-circle-flags';

import * as styled from './TitleWithFlag.styled';

interface Props {
  title: string;
  country: string;
  hideOnMobile?: boolean;
}

function TitleWithFlag({title, country, hideOnMobile}: Props) {
  const countryCode = country.toLocaleLowerCase();

  return (
    <styled.title hideOnMobile={hideOnMobile}>
      <CircleFlag countryCode={countryCode} height="55" /> {title}
    </styled.title>
  );
}

export default TitleWithFlag;
