export const rightPanelWidth = '25rem';
export const headerHeight = '6rem';

export const colors = {
  primary: '#084298',
  primary_background: '#cfe2ff',
  primary_border: '#b6d4fe',

  success: '#0f5132',
  success_background: '#d1e7dd',
  success_border: '#badbcc',

  warning: '#664d03',
  warning_background: '#fff3cd',
  warning_border: '#ffecb5',

  danger: '#842029',
  danger_background: '#f8d7da',
  danger_border: '#f5c2c7',

  light: '#636464',
  light_background: '#fbfbfb',
  light_border: '#fdfdfe',

  icon_primary: '#006EFF',
  icon_secondary: '#696969'
};

export const mediaQueries = {
  mobile: 'screen and (min-width: 768px)',
  tablet: 'screen and (min-width: 992px)',
  desktop: 'screen and (min-width: 1200px)'
};
