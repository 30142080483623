import {useState, useEffect, useCallback} from 'react';
import {CustomLabels} from 'react-flags-select/build/types';

import literalsCommon from 'localization/common';

import * as styled from './CountrySelect.styled';

interface Props extends BaseProps {
  id: string;
  countryCode: string;
  countriesList: Country[];
  onChange: (countryCode: string) => void;
}

function CountrySelect({id, countryCode, countriesList, className, onChange}: Props) {
  const [countries, setCountries] = useState<string[]>([]);
  const [customLabels, setCustomLabels] = useState<CustomLabels>({});

  const loadContriesDataForDropdown = useCallback(() => {
    const mySort = (s1, s2) => {
      return s1.label.localeCompare(s2.label);
    };

    const arrayToSort = [...countriesList];
    const countriesSorted = arrayToSort.sort(mySort);

    const countryCodes = countriesSorted.map(item => item.code.toUpperCase());
    setCountries(countryCodes);

    const labels: CustomLabels = {};

    countriesSorted.forEach((item: Country) => {
      labels[item.code.toUpperCase()] = item.label;
    });

    setCustomLabels(labels);
  }, [countriesList]);

  useEffect(() => {
    loadContriesDataForDropdown();

    // focus search input when click on 'react-flags-select' component
    const flagsSelect = document.getElementById(id);
    if (!flagsSelect) return;

    flagsSelect.addEventListener('click', () => {
      // add timeout to wait input render
      setTimeout(() => {
        const inputElement: HTMLElement | null = flagsSelect.querySelector('input[name=rfs-q]');
        if (inputElement) inputElement.focus();
      }, 50);
    });
  }, [countriesList, loadContriesDataForDropdown, id]);

  return (
    <styled.countrySelect
      id={id}
      className={className}
      selectButtonClassName="menu-flags-button"
      selected={countryCode.toUpperCase()}
      countries={countries}
      customLabels={customLabels}
      placeholder={literalsCommon.countrySelect}
      searchable
      selectedSize={20}
      optionsSize={18}
      searchPlaceholder={literalsCommon.enterCountryName}
      onSelect={code => onChange(code.toLowerCase())}
    />
  );
}

export default CountrySelect;
