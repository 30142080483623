import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationCountryInfo {
  pleaseSelectCountry: string;
  noCountryInfo: string;
  shareInformation: string;
  telegramBot: string;
  addOverviewInfo: string;
  selectCategory: string;
  provideText: string;
  everythingRight: string;
  anyRemarks: string;
  comment: string;
  contactsForFeedback: string;
  email: string;
  telegram: string;
}

const literals = new LocalizedStrings({
  ua: {
    pleaseSelectCountry: 'Будь ласка, виберіть країну.',
    noCountryInfo: 'Вибачте, інформація про обрану країну, на даний момент, відсутня.',
    shareInformation: 'Ви можете поділитись інформацією скориставшись нашим',
    telegramBot: 'телеграм ботом.',
    addOverviewInfo: 'Додати оглядову інформацію',
    selectCategory: 'Виберіть категорію',
    provideText: 'Текст',
    everythingRight: 'Все вірно',
    anyRemarks: 'Є зауваження',
    comment: 'Коментар',
    contactsForFeedback: `Контакти для зворотнього зв'язку`,
    email: 'Електронна пошта',
    telegram: 'Телеграм'
  },
  ru: {
    pleaseSelectCountry: 'Пожалуйста, выберите страну.',
    noCountryInfo: 'Извините, информация по выбранной стране, на данный момент, отсутствует.',
    shareInformation: 'Вы можете поделиться информацией воспользовавшись нашим',
    telegramBot: 'телеграм ботом.',
    addOverviewInfo: 'Добавить обзорную информацию',
    selectCategory: 'Выберите категорию',
    provideText: 'Текст',
    everythingRight: 'Всё верно',
    anyRemarks: 'Есть замечания',
    comment: 'Комментарий',
    contactsForFeedback: 'Контакты для обратной связи',
    email: 'Электронная почта',
    telegram: 'Телеграм'
  }
});

export default helper.init<LocalizationCountryInfo>(literals);
