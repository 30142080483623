import {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {isEmpty} from 'lodash';
import styled from 'styled-components';

import countryActions from 'actions/countryActions';
import locationActions from 'actions/locationActions';

import {confirmActionCancel} from 'reducers/commonSlice';
import {closeArticle, selectCurrentArticle} from 'reducers/articleSlice';

import {useAppSelector, useAppDispatch} from 'hooks';

import AppPage from 'components/common/AppPage';
import Confirmation from 'components/common/Confirmation';
import ErrorBoundary from 'components/error/ErrorBoundary';
import ArticleModal from 'components/article/components/ArticleModal';

import 'styles/App.scss';

const StyledUiBlock = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  z-index: 999;
`;

interface Props {
  routes: any[];
  children?: any;
}

function App(props: Props) {
  const dispatch = useAppDispatch();

  const asyncAction = useAppSelector(state => state.common.asyncActions);
  const confirmAction = useAppSelector(state => state.common.confirmAction);
  const currentArticle = useAppSelector(selectCurrentArticle);

  useEffect(() => {
    dispatch(countryActions.loadCountries());
    dispatch(countryActions.loadOverviewCategories());
    dispatch(countryActions.loadInfoCategories());
    dispatch(locationActions.loadLocationInfoCategories());
  }, []);

  function cancelAction() {
    dispatch(confirmActionCancel());
  }

  function closeArticleModal() {
    dispatch(closeArticle());
  }

  function renderRoute(route, index: number) {
    const {pageProps, component: Component} = route;

    const wrapInAppPage = !isEmpty(pageProps);

    let render = props => <Component {...props} />;

    if (wrapInAppPage) {
      render = props => (
        <AppPage {...pageProps}>
          <Component {...props} />
        </AppPage>
      );
    }

    return <Route key={index} path={route.path} element={render(props)} />;
  }

  function render() {
    const showOverlay = isEmpty(asyncAction) ? false : true;
    const articleModalVisible = currentArticle ? true : false;

    return (
      <ErrorBoundary>
        {showOverlay && <StyledUiBlock />}

        {confirmAction && (
          <Confirmation
            title={confirmAction.title}
            text={confirmAction.text}
            visible={true}
            handleProceed={async () => {
              cancelAction();
              await confirmAction.action();
            }}
            handleClose={cancelAction}
          />
        )}

        {articleModalVisible && currentArticle && (
          <ArticleModal visible={articleModalVisible} slug={currentArticle} handleClose={closeArticleModal} />
        )}

        <Routes>{props.routes.map((route, index: number) => renderRoute(route, index))}</Routes>

        {props.children}
      </ErrorBoundary>
    );
  }

  return render();
}

export default App;
