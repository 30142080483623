import {IconContext} from 'react-icons';

//reduce bundle size by importing required icons only
import {
  FaPassport,
  FaVirus,
  FaEdit,
  FaPlusSquare,
  FaTrashAlt,
  FaCopy,
  FaInfoCircle,
  FaExclamationTriangle,
  FaRoad,
  FaHandHoldingMedical,
  FaGlobe,
  FaBus,
  FaChild,
  FaShoppingCart,
  FaHandsHelping,
  FaBriefcase,
  FaPaw,
  FaLightbulb,
  FaGooglePlay
} from 'react-icons/fa';
import {IoSettingsSharp, IoShuffle, IoCloseCircle} from 'react-icons/io5';
import {BsInfoCircle, BsBoxArrowInRight, BsBoxArrowRight, BsCurrencyExchange, BsBoxSeam} from 'react-icons/bs';
import {MdRule} from 'react-icons/md';
import {ImHome3} from 'react-icons/im';
import {GiSmartphone} from 'react-icons/gi';
import {FiMapPin} from 'react-icons/fi';
import {VscCommentDiscussion} from 'react-icons/vsc';

import {colors} from 'styles/shared';

const map = {
  visa: FaPassport,
  covid: FaVirus,
  edit: FaEdit,
  settings: IoSettingsSharp,
  add: FaPlusSquare,
  remove: FaTrashAlt,
  copy: FaCopy,
  login: BsBoxArrowInRight,
  logout: BsBoxArrowRight,
  info: FaInfoCircle,
  tip: FaLightbulb,
  danger: IoCloseCircle,
  warning: FaExclamationTriangle,
  crossBoard: MdRule,
  exitEntry: FaRoad,
  finances: BsCurrencyExchange,
  medicine: FaHandHoldingMedical,
  postal: BsBoxSeam,
  overview: FaGlobe,
  accommodation: ImHome3,
  transport: FaBus,
  resources: VscCommentDiscussion,
  withChildren: FaChild,
  shopping: FaShoppingCart,
  others: IoShuffle,
  entryRequirements: FaPassport,
  helpForUkrainians: FaHandsHelping,
  locations: FiMapPin,
  mobileAndInternet: GiSmartphone,
  work: FaBriefcase,
  withAnimals: FaPaw,
  appServices: FaGooglePlay,
  categoryDefault: BsInfoCircle
};

export type IconName =
  | 'visa'
  | 'covid'
  | 'edit'
  | 'settings'
  | 'add'
  | 'remove'
  | 'copy'
  | 'login'
  | 'logout'
  | 'info'
  | 'tip'
  | 'danger'
  | 'warning'
  | 'crossBoard'
  | 'exitEntry'
  | 'finances'
  | 'medicine'
  | 'postal'
  | 'overview'
  | 'accommodation'
  | 'transport'
  | 'resources'
  | 'withChildren'
  | 'shopping'
  | 'others'
  | 'entryRequirements'
  | 'helpForUkrainians'
  | 'locations'
  | 'mobileAndInternet'
  | 'work'
  | 'withAnimals'
  | 'appServices'
  | 'categoryDefault';

type IconSize = 'sm' | 'lg' | '2x' | '3x';

interface Props {
  name: IconName;
  size?: IconSize;
  color?: string;
}

const AppIcon = (props: Props) => {
  const {name, size = '2x', color = colors.icon_primary} = props;

  const getIconSize = () => {
    switch (size) {
      case 'sm':
        return '0.875em';
      case 'lg':
        return '1.25em';
      case '2x':
        return '2em';
      case '3x':
        return '3em';
      default:
        return '2em';
    }
  };

  const Icon = map[name];

  return (
    <IconContext.Provider value={{color, size: getIconSize()}}>
      <Icon />
    </IconContext.Provider>
  );
};

export default AppIcon;
