import {useState} from 'react';
import {isEmpty} from 'lodash';
import {Accordion, Button, ToggleButton} from 'components/bootstrap';

import {toggleEmptyCategoriesVisibility} from 'reducers/commonSlice';

import {useAppSelector, useAppDispatch} from 'hooks';
import useCodeParameter from 'hooks/useCodeParameter';

import literalsCommon from 'localization/common';

import * as styled from './Accordion.styled';

export const TOGGLER = {
  HIDE: 'hide',
  SHOW: 'show'
};

const togglers = [
  {name: literalsCommon.hideEmpty, value: TOGGLER.HIDE},
  {name: literalsCommon.showEmpty, value: TOGGLER.SHOW}
];

interface Props {
  header?: string;
  codes: string[];
  isEditMode?: boolean;
  children: any;
}

function AccordionComponent({header, codes, isEditMode, children}: Props) {
  const dispatch = useAppDispatch();

  const categoriesVisibility = useAppSelector(state => state.common.categoriesVisibility);

  const [activeKey, setActiveKey] = useState<string[] | string>([]);

  useCodeParameter(setActiveKey);

  function toggleItem(eventKey?: string | string[] | null) {
    if (!eventKey) return setActiveKey([]);

    setActiveKey(eventKey);
  }

  function expandAll() {
    if (isEmpty(activeKey)) {
      setActiveKey(codes);
    } else {
      setActiveKey([]);
    }
  }

  function toggleVisibility(value: string) {
    dispatch(toggleEmptyCategoriesVisibility(value));
  }

  function renderToolbar() {
    const toggler = activeKey.length > 1 ? literalsCommon.collapseAll : literalsCommon.expandAll;
    const withHeader = header ? true : false;

    return (
      <styled.toolbar withHeader={withHeader}>
        {withHeader && <h3>{header}</h3>}

        <styled.toolbarButtons>
          {isEditMode && (
            <styled.toggler>
              {togglers.map((item, index) => {
                const id = `radio-${index}`;
                const variant = index % 2 ? 'outline-success' : 'outline-danger';
                const isChecked = categoriesVisibility === item.value;

                return (
                  <ToggleButton
                    key={index}
                    id={id}
                    type="radio"
                    variant={variant}
                    name="radio"
                    value={item.value}
                    checked={isChecked}
                    onChange={e => toggleVisibility(e.target.value)}>
                    {item.name}
                  </ToggleButton>
                );
              })}
            </styled.toggler>
          )}

          <Button variant="outline-secondary" onClick={expandAll}>
            {toggler}
          </Button>
        </styled.toolbarButtons>
      </styled.toolbar>
    );
  }

  function render() {
    return (
      <>
        {renderToolbar()}

        <Accordion alwaysOpen activeKey={activeKey} onSelect={toggleItem}>
          {children}
        </Accordion>
      </>
    );
  }

  return render();
}

export default AccordionComponent;
