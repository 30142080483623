import styled from 'styled-components';
import {Accordion} from 'components/bootstrap';

export const container = styled(Accordion.Header)`
  &.empty-category .accordion-button {
    background-color: beige;
  }
`;

export const icon = styled.div`
  cursor: pointer;
  margin-right: 1rem;
`;
