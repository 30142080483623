import styled from 'styled-components';

export const wrapper = styled.div`
  margin-top: 4rem;
`;

export const header = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const toolbar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const icon = styled.div`
  cursor: pointer;
`;
