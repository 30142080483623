import {useState, useEffect} from 'react';
import {Modal, Button, Form} from 'components/bootstrap';
import {isEmpty} from 'lodash';

import literalsCommon from 'localization/common';
import literalsCountryInfo from 'localization/countryInfo';

interface Props {
  visible: boolean;
  countryOverview: CountryOverviewItem[];
  countryOverviewCategoriesLookup: Record<string, CountryOverviewCategory>;
  handleSaveChanges: (categoryCode: string, text: string) => void;
  handleClose: () => void;
}

function EditCountryOverviewModal({
  visible,
  countryOverview,
  countryOverviewCategoriesLookup,
  handleSaveChanges,
  handleClose
}: Props) {
  const [options, setOptions] = useState<Option[]>([]);
  const [categoryCode, setCategoryCode] = useState<string>('');
  const [text, setText] = useState<string>('');

  useEffect(() => {
    const optionsData: Option[] = Object.keys(countryOverviewCategoriesLookup).map((code: string) => {
      return {value: code, label: countryOverviewCategoriesLookup[code].label};
    });

    setOptions(optionsData);
  }, []);

  useEffect(() => {
    if (isEmpty(countryOverview)) return;

    const countryOverviewItem = countryOverview.find(item => item.category.code === categoryCode);

    if (!countryOverviewItem) return;

    setText(countryOverviewItem.text);
  }, [categoryCode]);

  async function onSave() {
    await handleSaveChanges(categoryCode, text);
  }

  function render() {
    const buttonDisabled = !categoryCode || !text;

    return (
      <Modal show={visible} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{literalsCommon.editContent}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formSelectCategory">
              <Form.Label>{literalsCountryInfo.selectCategory}</Form.Label>
              <Form.Select
                aria-label={literalsCountryInfo.selectCategory}
                value={categoryCode}
                onChange={e => setCategoryCode(e.target.value)}>
                {!categoryCode && <option>{literalsCountryInfo.selectCategory}</option>}

                {options.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTextArea">
              <Form.Label>{literalsCountryInfo.provideText}</Form.Label>
              <Form.Control as="textarea" rows={3} value={text} onChange={e => setText(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {literalsCommon.close}
          </Button>
          <Button variant="primary" disabled={buttonDisabled} onClick={onSave}>
            {literalsCommon.save}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return render();
}

export default EditCountryOverviewModal;
