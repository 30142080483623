export default {
  getRelativeUrlPathForArticle,
  getRelativeUrlPathForInfo
};

function getRelativeUrlPathForArticle(slug: string) {
  return `/article/${slug}`;
}

function getRelativeUrlPathForInfo(countryCode?: string) {
  if (!countryCode) return '/info';

  return `/info?countryCode=${countryCode}`;
}
