import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';

import countryReducer from 'reducers/countrySlice';
import commonReducer from 'reducers/commonSlice';
import userReducer from 'reducers/userSlice';
import articleReducer from 'reducers/articleSlice';
import locationReducer from 'reducers/locationSlice';

export const store = configureStore({
  reducer: {
    country: countryReducer,
    common: commonReducer,
    user: userReducer,
    article: articleReducer,
    location: locationReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
