import styled from 'styled-components';

export const icons = styled.div`
  display: flex;
`;

export const icon = styled.div`
  cursor: pointer;
  margin-left: 1rem;
`;
