import styled from 'styled-components';

import {Modal} from 'components/bootstrap';

import {mediaQueries} from 'styles/shared';

export const header = styled(Modal.Header)`
  position: relative;
`;

export const toolbar = styled.div`
  position: absolute;
  left: 60%;

  @media ${mediaQueries.mobile} {
    left: 50%;
  }
`;

export const icon = styled.div`
  cursor: pointer;
  margin: 0 1rem;
`;
