import styled from 'styled-components';
import {Button, Modal} from 'components/bootstrap';

import Container from 'components/common/Container';

import {mediaQueries} from 'styles/shared';

export const wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5rem 2rem;
  margin-top: 0;

  @media ${mediaQueries.mobile} {
    width: 60%;
    padding: 0 2rem 2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const title = styled.div`
  font-size: 2rem;
  text-align: center;

  @media ${mediaQueries.mobile} {
    font-size: 2.8rem;
  }
`;

export const header = styled(Modal.Header)`
  flex-direction: column;

  @media ${mediaQueries.mobile} {
    flex-direction: row;
  }
`;

export const buttonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${mediaQueries.mobile} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const button = styled(Button)`
  margin: 1rem 0 0;

  @media ${mediaQueries.mobile} {
    margin: 0 0 0 1rem;
  }
`;
