import {useState, useEffect} from 'react';
import {isEmpty} from 'lodash';
import {Accordion, Container} from 'components/bootstrap';

import {selectUserPermissions} from 'reducers/userSlice';

import {useAppSelector} from 'hooks';

import literalsNavigation from 'localization/navigation';

import dataService from 'services/dataService';

import EditContentAction from 'components/common/EditContentAction';
import ReactMarkdownRender from 'components/react_markdown';
import EditorInfo from 'components/common/EditorInfo';
import Feedback from 'components/common/Feedback';
import AccordionComponent from 'components/common/Accordion';
import AccordionHeader from 'components/common/Accordion/header/AccordionHeader';

import * as styled from './GeneralPage.styled';

function GeneralPage() {
  const permissions = useAppSelector(selectUserPermissions);

  const [generalInfo, setGeneralInfo] = useState<GeneralInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadGeneralInfo();
  }, []);

  async function loadGeneralInfo() {
    setIsLoading(true);
    const info: GeneralInfo[] = await dataService.getGeneralInfo();
    setGeneralInfo(info);
    setIsLoading(false);
  }

  async function handleUpdate() {
    await loadGeneralInfo();
  }

  return (
    <styled.wrapper>
      <Container>
        {!isLoading && (
          <>
            {!isEmpty(generalInfo) && (
              <AccordionComponent
                header={literalsNavigation.generalRecommendations}
                codes={generalInfo.map(item => item.code)}>
                {generalInfo.map(info => {
                  const code = info.code;

                  if (!permissions?.generalInfo && !info.content) return null;

                  return (
                    <Accordion.Item key={code} eventKey={code}>
                      <AccordionHeader title={info.title} code={code} icon={info.icon} />

                      <Accordion.Body>
                        <ReactMarkdownRender>{info.content}</ReactMarkdownRender>

                        {permissions?.generalInfo ? (
                          <EditContentAction
                            contentId={info.contentId}
                            content={info.content}
                            onUpdate={handleUpdate}
                            handleNewContentCreation={() => 0}
                          />
                        ) : (
                          <Feedback type="general_info" data={{code}} />
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </AccordionComponent>
            )}

            {permissions?.generalInfo && <EditorInfo />}
          </>
        )}

        {isLoading && <styled.loader />}
      </Container>
    </styled.wrapper>
  );
}

export default GeneralPage;
