import {Container, Nav, Navbar} from 'components/bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';

import {useAppSelector, useAppDispatch} from 'hooks';

import userActions from 'actions/userActions';

import literalsCommon from 'localization/common';
import literalsNavigation from 'localization/navigation';

import AppIcon from 'components/common/AppIcon';

import * as styled from './Navigation.styled';

function Navigation() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const user = useAppSelector(state => state.user.current);

  const [expanded, setExpanded] = useState(false);

  async function logout(e) {
    if (e) e.preventDefault();

    await dispatch(userActions.logOut());

    navigate('/login');
  }

  function renderNavLink(path: string, label: string) {
    return (
      <Nav.Link as={Link} href={path} to={path} active={pathName === path} onClick={() => setExpanded(false)}>
        {label}
      </Nav.Link>
    );
  }

  return (
    <Navbar bg="light" variant="light" expand="lg" fixed="top" expanded={expanded}>
      <Container fluid>
        <styled.navbar href="/">
          <img src="/images/logo/logo_1.jpg" alt={literalsCommon.projectName} width="35" />
          <styled.header>{literalsCommon.projectName}</styled.header>
        </styled.navbar>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(val => !val)} />

        <styled.navCollapse id="basic-navbar-nav">
          <Nav>
            {renderNavLink('/', literalsNavigation.aboutProject)}
            {renderNavLink('/general', literalsNavigation.generalRecommendations)}
            {renderNavLink('/info', literalsNavigation.countryInformation)}
          </Nav>
        </styled.navCollapse>

        <styled.navCollapse>
          {user && (
            <styled.logoutWrapper>
              <styled.username>{user.username}</styled.username>

              <styled.logoutContainer href="#" onClick={logout}>
                <styled.logoutText>{literalsNavigation.logout}</styled.logoutText>{' '}
                <AppIcon name="logout" size="sm" color="black" />
              </styled.logoutContainer>
            </styled.logoutWrapper>
          )}
        </styled.navCollapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
