import styled from 'styled-components';

import Container from '../common/Container';

import {mediaQueries, rightPanelWidth} from 'styles/shared';

export const wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

export const container = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  text-align: center;

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const content = styled.div`
  padding: 0 1rem 2rem;
  text-align: initial;
  width: 100%;

  @media ${mediaQueries.desktop} {
    width: calc(100% - ${rightPanelWidth});
    padding: 0 4rem 4rem;
  }
`;

export const description = styled.p`
  line-height: 1.2;
  font-size: 1.2rem;
  text-align: center;
  padding-top: 5rem;
`;

export const reference = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin: 1rem 0 4rem 0;
`;
