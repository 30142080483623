import LocalizedStrings from 'react-localization';
import helper from './localizationHelper';

export interface LocalizationCommon {
  projectName: string;
  collapseAll: string;
  expandAll: string;
  contentSaved: string;
  edit: string;
  editContent: string;
  close: string;
  save: string;
  confirmationTitle: string;
  confirmationText: string;
  countrySelect: string;
  enterCountryName: string;
  addNewRecord: string;
  note: string;
  tip: string;
  warning: string;
  danger: string;
  send: string;
  feedback: string;
  hideEmpty: string;
  showEmpty: string;
  copyLink: string;
  linkCopied: string;
  help: string;
  yes: string;
  no: string;
  feedbackSuccessfullySend: string;
}

const literals = new LocalizedStrings({
  ua: {
    projectName: 'Валіза',
    collapseAll: 'Згорнути все',
    expandAll: 'Розгорнути все',
    contentSaved: 'Контент збережено',
    edit: 'Редагувати',
    editContent: 'Редагувати контент',
    close: 'Закрити',
    save: 'Зберегти',
    confirmationTitle: 'Підтвердження',
    confirmationText: 'Ви впевнені?',
    countrySelect: 'Виберіть країну',
    enterCountryName: 'Введіть назву країни...',
    addNewRecord: 'Додати новий запис',
    note: 'Примітка',
    tip: 'Порада',
    warning: 'Увага',
    danger: 'Попередження',
    send: 'Відправити',
    feedback: 'Відгук',
    hideEmpty: 'Сховати порожні',
    showEmpty: 'Показати порожні',
    copyLink: 'Копіювати посилання',
    linkCopied: 'Посилання успішно скопійоване.',
    help: 'Markdown розмітка для контента',
    yes: 'Так',
    no: 'Ні',
    feedbackSuccessfullySend: 'Відгук успішно надіслано!'
  },
  ru: {
    projectName: 'Вализа',
    collapseAll: 'Свернуть всё',
    expandAll: 'Развернуть всё',
    contentSaved: 'Контент сохранен',
    edit: 'Редактировать',
    editContent: 'Редактировать контент',
    close: 'Закрыть',
    save: 'Сохранить',
    confirmationTitle: 'Подтверждение',
    confirmationText: 'Вы уверены?',
    countrySelect: 'Выбирете страну',
    enterCountryName: 'Введите название страны...',
    addNewRecord: 'Добавить новую запись',
    note: 'Примечание',
    tip: 'Совет',
    warning: 'Внимание',
    danger: 'Предупреждение',
    send: 'Отправить',
    feedback: 'Отзыв',
    hideEmpty: 'Скрыть пустые',
    showEmpty: 'Показать пустые',
    copyLink: 'Копировать ссылку',
    linkCopied: 'Ссылка успешно скопирована.',
    help: 'Markdown разметка для контента',
    yes: 'Да',
    no: 'Нет',
    feedbackSuccessfullySend: 'Отзыв успешно отправлен!'
  }
});

export default helper.init<LocalizationCommon>(literals);
