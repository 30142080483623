import styled from 'styled-components';

import {Button} from 'components/bootstrap';

export const container = styled.div`
  display: flex;
  justify-content: end;
  margin: 0.5rem 0;
`;

export const button = styled(Button)`
  margin-left: 1rem;
`;
