import {Modal, Button, Form} from 'components/bootstrap';

import literalsCommon from 'localization/common';
import literalsLocation from 'localization/location';

import TextInput from 'components/common/TextInput';

interface Props {
  visible: boolean;
  location: LocationItem;
  handleChangeLocation: (field: string, value: string) => void;
  handleSaveLocation: () => void;
  handleClose: () => void;
}

function LocationModal({visible, location, handleChangeLocation, handleSaveLocation, handleClose}: Props) {
  const title = location.id ? literalsLocation.addNewLocation : literalsLocation.editLocation;
  const buttonDisabled = !location.code && !location.label;

  return (
    <Modal show={visible} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <TextInput
            name="code"
            label={literalsLocation.locationCode}
            value={location.code}
            onChange={handleChangeLocation}
            placeholder={literalsLocation.locationCodePlaceholder}
          />

          <TextInput
            name="label"
            label={literalsLocation.locationLabel}
            value={location.label}
            onChange={handleChangeLocation}
            placeholder={literalsLocation.locationLabelPlaceholder}
          />
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {literalsCommon.close}
        </Button>
        <Button variant="primary" disabled={buttonDisabled} onClick={handleSaveLocation}>
          {literalsCommon.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationModal;
