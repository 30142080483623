import {useState, useEffect} from 'react';

import literalsCommon from 'localization/common';

import config from 'config';

import dataService from 'services/dataService';

import ReactMarkdownRender from 'components/react_markdown';

import * as styled from './HomePage.styled';

function HomePage() {
  const [content, setContent] = useState<string>();

  useEffect(() => {
    loadContent();
  }, []);

  async function loadContent() {
    const response: Article | undefined = await dataService.getArticle(config.articleSlugs.about_project);
    setContent(response?.content);
  }

  return (
    <styled.wrapper>
      <styled.header>
        <img src="/images/logo/logo_1.jpg" alt={literalsCommon.projectName} width="55" />
        {literalsCommon.projectName}
      </styled.header>

      <ReactMarkdownRender>{content}</ReactMarkdownRender>
    </styled.wrapper>
  );
}

export default HomePage;
