const isDevLocal = process.env.NODE_ENV !== 'production';

export default {
  isDevLocal,
  baseUrl: process.env.REACT_APP_BASE_URL,
  telegramBot: isDevLocal ? 'https://t.me/ValizaInfoQaBot' : 'https://t.me/ValizaInfoBot',
  telegramBotName: isDevLocal ? 'ValizaInfoQaBot' : 'ValizaInfoBot',
  productionBaseUrl: 'www.valiza.info',
  googleAnalytics: {
    valizaClubMeasurementId: 'G-D0RRBCGV6P',
    valizaInfoMeasurementId: 'G-GYWV56JFTC'
  },
  articleSlugs: {
    markdown_info: '0906cdd91842315362b002a06',
    about_project: '7c2e1c6d7955342c4b9837b21'
  }
};
