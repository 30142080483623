import {Modal, Button} from 'components/bootstrap';

import literalsCommon from 'localization/common';

interface Props {
  visible: boolean;
  title?: string;
  text?: string;
  handleClose: () => void;
  handleProceed: () => void;
}

function Confirmation({visible, title, text, handleClose, handleProceed}: Props) {
  const titleDisplay = title ? title : literalsCommon.confirmationTitle;
  const textDisplay = text ? text : literalsCommon.confirmationText;

  return (
    <Modal show={visible} backdrop="static" onHide={handleClose}>
      <Modal.Header>
        <h4>{titleDisplay}</h4>
      </Modal.Header>

      <Modal.Body>
        <h5>{textDisplay}</h5>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {literalsCommon.no}
        </Button>
        <Button variant="primary" onClick={handleProceed}>
          {literalsCommon.yes}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Confirmation;
