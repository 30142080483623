import styled from 'styled-components';
import {Row} from 'components/bootstrap';

export const wrapper = styled.div`
  width: 100%;
  padding: 3rem 1rem 0;
`;

export const header = styled(Row)`
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
`;

export const title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  float: right;
`;

export const locationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const icons = styled.div`
  display: flex;
`;

export const icon = styled.div`
  cursor: pointer;
  margin-left: 1rem;
`;

export const addIcon = styled.div`
  cursor: pointer;
  float: right;
`;
