import * as styled from './Container.styled';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: any;
}

function Container({children, className}: Props) {
  return <styled.container className={className}>{children}</styled.container>;
}

export default Container;
