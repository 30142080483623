import {loadCurrentUser} from 'reducers/userSlice';

import helper from './actionHelper';

import authService from 'services/authService';

export default {
  getCurrentUser,
  loginUser,
  logOut
};

function getCurrentUser() {
  return helper.dispatchAsyncAction(async dispatch => {
    const user = await authService.getCurrentUser();
    dispatch(loadCurrentUser(user));
  });
}

function loginUser(username: string, password: string) {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.login(username, password);

    if (response?.token) authService.saveToken(response.token);
  });
}

function logOut() {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(loadCurrentUser(undefined));
    authService.saveToken(null);
  });
}
