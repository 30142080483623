import {useState, useEffect, useCallback} from 'react';
import {Accordion, OverlayTrigger, Tooltip} from 'components/bootstrap';

import literalsCommon from 'localization/common';
import literalsArticle from 'localization/article';
import {DEFAULT_LANGUAGE} from 'localization/localizationHelper';

import uiHelper from 'helpers/uiHelper';

import dataService from 'services/dataService';

import AppIcon from 'components/common/AppIcon';
import EditContentModal from 'components/common/EditContentModal';
import Confirmation from 'components/common/Confirmation';

import EditorInfoItem from './components/editor_info_item/EditorInfoItem';
import SaveArticleModal from './components/save_article_modal/SaveArticleModal';

import * as styled from './EditorInfo.styled';

type documentType = 'article' | 'note';

interface Props {
  countryCode?: string;
}

function EditorInfo({countryCode}: Props) {
  const [activeKey, setActiveKey] = useState<string[] | string>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [notes, setNotes] = useState<Article[]>([]);

  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);
  const [contentToEdit, setContentToEdit] = useState<string | undefined>(undefined);
  const [contentIdToEdit, setContentIdToEdit] = useState<number | undefined>(undefined);
  const [documentToRemove, setDocumentToRemove] = useState<Article | null>(null);

  const loadArticles = useCallback(async () => {
    const articlesData = await dataService.getArticles(countryCode);

    setNotes(articlesData.filter(item => item.isNote));
    setArticles(articlesData.filter(item => !item.isNote));
  }, [countryCode]);

  useEffect(() => {
    loadArticles();
  }, []);

  function toggleItem(eventKey?: string | string[] | null) {
    if (!eventKey) return setActiveKey([]);

    setActiveKey(eventKey);
  }

  function handleClose() {
    setArticleToEdit(null);
  }

  function handleAddDocument(documentType: documentType) {
    const document: Article = {
      title: '',
      urlSlug: '',
      countryCode,
      isNote: documentType === 'note',
      language: DEFAULT_LANGUAGE
    };

    setArticleToEdit(document);
  }

  function handleEditDocument(item: Article) {
    setArticleToEdit(item);
  }

  function onArticleChange(field: string, value: string) {
    if (!articleToEdit) return;
    setArticleToEdit({...articleToEdit, [field]: value});
  }

  async function saveDocument(articleData: Article) {
    // update document
    if (articleData.id) {
      const article: Article | null = await dataService.updateArticle(articleData);
      if (article) await onSuccess(articleData.isNote);
      return;
    }

    const contentId = await dataService.addArticle(articleData);
    if (!contentId) return;

    await onSuccess(articleData.isNote);
  }

  async function onSuccess(isNote: boolean) {
    const message = isNote ? literalsArticle.noteSaved : literalsArticle.articleSaved;
    uiHelper.showMessage(message);

    await loadArticles();
    handleClose();
  }

  function handleCloseEditContentModal() {
    setContentToEdit(undefined);
    setContentIdToEdit(undefined);
  }

  function handleShowContentModal(item: Article) {
    setContentToEdit(item.content);
    setContentIdToEdit(item.contentId);
  }

  async function handleUpdateContent(contentBody: string) {
    if (!contentIdToEdit) return;

    await dataService.updateContent(contentIdToEdit, contentBody);
    await loadArticles();
  }

  function handleShowConfirmation(article: Article) {
    setDocumentToRemove(article);
  }

  function handleCloseConfirmation() {
    setDocumentToRemove(null);
  }

  async function removeDocument() {
    if (!documentToRemove?.id) return;

    const isSuccess: boolean = await dataService.removeArticle(documentToRemove.id, documentToRemove.contentId);

    if (isSuccess) {
      await loadArticles();
      uiHelper.showMessage(literalsArticle.articleRemoved);
    }

    handleCloseConfirmation();
  }

  function renderToolbar(documentType: documentType) {
    const tooltipId = `tooltip-add-${documentType}`;

    return (
      <styled.toolbar>
        <OverlayTrigger placement="right" overlay={<Tooltip id={tooltipId}>{literalsCommon.addNewRecord}</Tooltip>}>
          <styled.icon onClick={() => handleAddDocument(documentType)}>
            <AppIcon name="add" size="3x" color="green" />
          </styled.icon>
        </OverlayTrigger>
      </styled.toolbar>
    );
  }

  function renderEditorInfoItem(item: Article) {
    return (
      <EditorInfoItem
        key={item.id}
        item={item}
        handleEditDocument={handleEditDocument}
        handleShowContentModal={handleShowContentModal}
        handleShowConfirmation={handleShowConfirmation}
      />
    );
  }

  function render() {
    const addArticleModalVisible = articleToEdit ? true : false;
    const editModalVisible = contentIdToEdit ? true : false;
    const confirmationModalVisible = documentToRemove ? true : false;

    return (
      <styled.wrapper>
        <styled.header>{literalsArticle.forEditor}:</styled.header>

        <Accordion alwaysOpen activeKey={activeKey} onSelect={toggleItem}>
          <Accordion.Item eventKey="articles">
            <Accordion.Header>{literalsArticle.articles}</Accordion.Header>
            <Accordion.Body>
              {renderToolbar('article')}

              {articles.map(article => {
                return renderEditorInfoItem(article);
              })}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="notes">
            <Accordion.Header>{literalsArticle.notes}</Accordion.Header>
            <Accordion.Body>
              {renderToolbar('note')}

              {notes.map(note => {
                return renderEditorInfoItem(note);
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {articleToEdit && (
          <SaveArticleModal
            visible={addArticleModalVisible}
            handleClose={handleClose}
            articleToEdit={articleToEdit}
            onSave={saveDocument}
            handleChange={onArticleChange}
          />
        )}

        {editModalVisible && (
          <EditContentModal
            visible={editModalVisible}
            content={contentToEdit}
            handleClose={handleCloseEditContentModal}
            handleSaveChanges={handleUpdateContent}
          />
        )}

        {confirmationModalVisible && (
          <Confirmation
            visible={confirmationModalVisible}
            handleClose={handleCloseConfirmation}
            handleProceed={removeDocument}
          />
        )}
      </styled.wrapper>
    );
  }

  return render();
}

export default EditorInfo;
