import styled, {css} from 'styled-components';
import {rgba} from 'polished';

import {sectionType} from './SectionComponent';

import {colors} from 'styles/shared';

const getBackgroundColor = color => {
  return rgba(color, 0.3);
};

export const section = styled.section<{type: sectionType}>`
  box-sizing: border-box;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  ${props =>
    props.type === 'default' &&
    css`
      color: ${colors.light};
      background-color: ${colors.light_background};
      border-color: ${colors.light_border};
      margin: 0 0.7rem 0.4rem;
      padding: 0.2rem 0.8rem 0;
    `};

  ${props =>
    props.type === 'note' &&
    css`
      color: ${colors.primary};
      opacity: 0.9;
      background-color: ${getBackgroundColor(colors.primary_background)};
      border-color: ${colors.primary_border};
    `};

  ${props =>
    props.type === 'tip' &&
    css`
      color: ${colors.success};
      background-color: ${getBackgroundColor(colors.success_background)};
      border-color: ${colors.success_border};
    `};

  ${props =>
    props.type === 'warning' &&
    css`
      color: ${colors.warning};
      background-color: ${getBackgroundColor(colors.warning_background)};
      border-color: ${colors.warning_border};
    `};

  ${props =>
    props.type === 'danger' &&
    css`
      color: ${colors.danger};
      background-color: ${getBackgroundColor(colors.danger_background)};
      border-color: ${colors.danger_border};
    `};
`;

export const title = styled.p`
  display: flex;
  align-items: center;
  font-weight: bold;

  & svg {
    margin-right: 0.8rem;
  }
`;
