import {useState, useEffect, useCallback} from 'react';
import {isEmpty} from 'lodash';
import {Button} from 'components/bootstrap';

import literalsCommon from 'localization/common';
import literalsCountryInfo from 'localization/countryInfo';

import uiHelper from 'helpers/uiHelper';

import dataService from 'services/dataService';

import OverviewItem from './components/overview_item/OverviewItem';
import EditCountryOverviewModal from './components/overview_edit_modal/EditCountryOverviewModal';
import Locations from './components/locations/Locations';

import * as styled from './RightPanel.styled';

interface Props {
  countryCode: string;
  countriesList: Country[];
  selectCountry: (countryCode: string) => void;
  countryOverviewCategoriesLookup: Record<string, CountryOverviewCategory>;
  isEditMode: boolean;
}

function RightPanel({countryCode, countriesList, selectCountry, countryOverviewCategoriesLookup, isEditMode}: Props) {
  const [countryOverview, setCountryOverview] = useState<CountryOverviewItem[]>([]);
  const [isCountryOverviewEditModalVisible, setIsCountryOverviewEditModalVisible] = useState<boolean>(false);

  const initData = useCallback(async () => {
    if (!countryCode) {
      setCountryOverview([]);
      return;
    }

    const result: CountryOverviewItem[] = await dataService.getCountryOverview(
      countryCode,
      countryOverviewCategoriesLookup
    );
    setCountryOverview(result);
  }, [countryCode, countryOverviewCategoriesLookup]);

  useEffect(() => {
    initData();
  }, [countryCode, initData]);

  function toggleEditModal() {
    setIsCountryOverviewEditModalVisible(visible => !visible);
  }

  async function handleSaveChanges(categoryCode: string, text: string) {
    const entityId = await dataService.saveCountryOverview(countryCode, categoryCode, text);

    if (!entityId) return;

    await initData();
    toggleEditModal();
    uiHelper.showMessage(literalsCommon.contentSaved);
  }

  function renderActionButton() {
    if (!isEditMode || !countryCode) return null;

    const title = isEmpty(countryOverview) ? literalsCountryInfo.addOverviewInfo : literalsCommon.edit;

    return (
      <styled.buttonContainer withMargin={!isEmpty(countryOverview)}>
        <Button onClick={toggleEditModal}>{title}</Button>
      </styled.buttonContainer>
    );
  }

  function render() {
    return (
      <styled.wrapper>
        <styled.countrySelect
          id="country-info-select"
          countryCode={countryCode}
          countriesList={countriesList}
          onChange={selectCountry}
        />

        {countryOverview.map((item: CountryOverviewItem) => {
          return item.category?.code ? <OverviewItem key={item.category.code} item={item} /> : null;
        })}

        {renderActionButton()}

        <Locations isEditMode={isEditMode} countryCode={countryCode} />

        {isCountryOverviewEditModalVisible && (
          <EditCountryOverviewModal
            visible={isCountryOverviewEditModalVisible}
            countryOverview={countryOverview}
            countryOverviewCategoriesLookup={countryOverviewCategoriesLookup}
            handleSaveChanges={handleSaveChanges}
            handleClose={toggleEditModal}
          />
        )}
      </styled.wrapper>
    );
  }

  return render();
}

export default RightPanel;
