import {startsWith, split} from 'lodash';

import {useAppDispatch} from 'hooks';

import {openArticle} from 'reducers/articleSlice';

function LinkComponent(props: any) {
  const dispatch = useAppDispatch();

  const url = props.href;
  const text = props?.title || props?.children?.[0] || url;

  function openLink(e) {
    if (e) e.preventDefault();

    const arr = split(url, '/');
    const slug = arr[arr.length - 1];

    dispatch(openArticle(slug));
  }

  if (startsWith(url, '/article')) {
    return (
      <a href={url} onClick={openLink}>
        {text}
      </a>
    );
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
}

export default LinkComponent;
