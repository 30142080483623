import {Accordion} from 'components/bootstrap';

import dataService from 'services/dataService';

import Feedback from 'components/common/Feedback';
import ReactMarkdownRender from 'components/react_markdown';
import EditContentAction from 'components/common/EditContentAction';
import AccordionHeader from 'components/common/Accordion/header/AccordionHeader';

interface Props {
  locationInfo: LocationInfo;
  isEditMode: boolean;
  onUpdate: () => void;
}

function LocationInfoItem({locationInfo, isEditMode, onUpdate}: Props) {
  const {countryCode, locationCode, categoryCode, categoryLabel, categoryIcon, contentId, content} = locationInfo;

  const isEmptyCategory = content ? false : true;

  async function handleAddNewEntry() {
    return await dataService.addLocationInfoRecord(locationCode, categoryCode);
  }

  function render() {
    return (
      <Accordion.Item eventKey={categoryCode}>
        <AccordionHeader
          title={categoryLabel}
          code={categoryCode}
          icon={categoryIcon}
          isEmptyCategory={isEmptyCategory}
        />

        <Accordion.Body>
          {!isEmptyCategory && <ReactMarkdownRender>{content}</ReactMarkdownRender>}

          {isEditMode ? (
            <EditContentAction
              contentId={contentId}
              content={content}
              handleNewContentCreation={handleAddNewEntry}
              onUpdate={onUpdate}
            />
          ) : (
            <Feedback type="location_info" data={{code: categoryCode, country: countryCode, location: locationCode}} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  return render();
}

export default LocationInfoItem;
