import Cookies from 'js-cookie';

import httpHelper from 'helpers/httpHelper';

export default {
  getCurrentUser,
  login,
  getToken,
  saveToken
};

async function getCurrentUser(): Promise<User> {
  return await httpHelper.get('/api/current-user', {});
}

async function login(username: string, password: string) {
  const body = {
    username,
    password
  };

  return await httpHelper.post('/api/login', body);
}

function getToken() {
  const token = Cookies.get('jwt_token');
  return token;
}

function saveToken(jwt) {
  if (jwt) {
    Cookies.set('jwt_token', jwt);
    return;
  }

  Cookies.remove('jwt_token');
}
