import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import ReactGA from 'react-ga4';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.css';

import {routes} from './routes';
import App from './components/App';
import {store} from 'store';
import config from 'config';

//init google analytics
ReactGA.initialize([
  {trackingId: config.googleAnalytics.valizaClubMeasurementId},
  {trackingId: config.googleAnalytics.valizaInfoMeasurementId}
]);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <React.StrictMode>
        <App routes={routes} />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);
