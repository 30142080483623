import styled from 'styled-components';

import ReactFlagsSelect from 'react-flags-select';

export const countrySelect = styled(ReactFlagsSelect)`
  .menu-flags-button {
    padding: 0.2rem 0.5rem;

    > span > span:first-child {
      font-size: 1.5em;
    }
  }

  > ul {
    max-height: 300px;
  }
`;
