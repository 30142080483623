import {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Row, Col, Button} from 'components/bootstrap';
import {isEmpty} from 'lodash';
import TelegramLoginButton from 'react-telegram-login';

import userActions from 'actions/userActions';
import {useAppSelector, useAppDispatch} from 'hooks';

import config from 'config';
import validationHelper from 'helpers/validationHelper';

import localizationAuth from 'localization/auth';

import gaEvents from 'events/gaEvents';

import authService from 'services/authService';

import AppIcon from 'components/common/AppIcon';
import TextInput from 'components/common/TextInput';

import * as styled from './LoginPage.styled';

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const user = useAppSelector(state => state.user.current);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [errors, setErrors] = useState({username: '', password: ''});

  useEffect(() => {
    if (isEmpty(user)) return;
    navigate('/');
  }, [user]);

  useEffect(() => {
    if (!token) return;

    authService.saveToken(token);
    dispatch(userActions.getCurrentUser());
  }, [token]);

  function loginFormIsValid() {
    const errors = {
      username: '',
      password: ''
    };

    if (!username) {
      errors.username = localizationAuth.userNameRequired;
    }

    if (!password) {
      errors.password = localizationAuth.passwordRequired;
    }

    setErrors(errors);

    return validationHelper.isEmptyErrorObject(errors);
  }

  async function login(e) {
    if (e) e.preventDefault();

    if (!loginFormIsValid()) return;

    await dispatch(userActions.loginUser(username, password));

    gaEvents.eventLoginBasic();

    await dispatch(userActions.getCurrentUser());
  }

  return (
    <styled.wrapper>
      <Row>
        <Col md={{span: 4, offset: 4}}>
          <h1 className="mb-5">
            <AppIcon name="login" size="sm" /> {localizationAuth.login}
          </h1>

          <form onSubmit={login} autoComplete="off">
            <TextInput
              name="username"
              label={localizationAuth.userName}
              value={username}
              onChange={(field, value) => setUsername(value)}
              placeholder={localizationAuth.userName}
              error={errors.username}
            />

            <TextInput
              name="password"
              label={localizationAuth.password}
              type="password"
              value={password}
              onChange={(field, value) => setPassword(value)}
              placeholder={localizationAuth.password}
              error={errors.password}
            />

            <styled.buttonsRow>
              <Button variant="warning" size="lg" type="submit" onClick={login}>
                {localizationAuth.login}
              </Button>

              <TelegramLoginButton
                dataAuthUrl={`${config.baseUrl}/api/login-telegram`}
                botName={config.telegramBotName}
              />
            </styled.buttonsRow>
          </form>
        </Col>
      </Row>
    </styled.wrapper>
  );
}

export default LoginPage;
