import {
  loadCountries as getCountries,
  setCurrentCountry,
  loadOverviewCategories as getOverviewCategories,
  loadInfoCategories as getInfoCategories
} from 'reducers/countrySlice';

import dataService from 'services/dataService';
import helper from './actionHelper';

export default {
  loadCountries,
  saveCurrentCountry,
  loadOverviewCategories,
  loadInfoCategories,
  sendFeedback
};

function loadCountries() {
  return helper.dispatchAsyncAction(async dispatch => {
    const countries = await dataService.getCountries();
    dispatch(getCountries(countries));
  }, false);
}

function saveCurrentCountry(country: Country) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(setCurrentCountry(country));
  }, false);
}

function loadOverviewCategories() {
  return helper.dispatchAsyncAction(async dispatch => {
    const categories = await dataService.getCountryOverviewCategories();
    dispatch(getOverviewCategories(categories));
  }, false);
}

function loadInfoCategories() {
  return helper.dispatchAsyncAction(async dispatch => {
    const categories = await dataService.getCountryInfoCategories();
    dispatch(getInfoCategories(categories));
  }, false);
}

function sendFeedback(feedbackData: FeedbackDto) {
  return helper.dispatchAsyncAction(async () => {
    return await dataService.sendFeedback(feedbackData);
  }, false);
}
