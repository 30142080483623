import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import LinkComponent from './renders/LinkComponent';
import ImageComponent from './renders/ImageComponent';
import SectionComponent from './renders/SectionComponent';

const components = {
  a: LinkComponent,
  img: ImageComponent,
  section: SectionComponent
};

function ReactMarkdownRender(props: any) {
  return (
    <ReactMarkdown
      components={components}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      className="break-word">
      {props.children}
    </ReactMarkdown>
  );
}

export default ReactMarkdownRender;
