import {LocalizedStringsMethods} from 'react-localization';
import Cookies from 'js-cookie';

const COOKIE_LANG_KEY = 'language';

export const DEFAULT_LANGUAGE = 'ua';

export default {
  init,
  changeLanguage
};

const literalsList: LocalizedStringsMethods[] = [];

function changeLanguage(language) {
  Cookies.set(COOKIE_LANG_KEY, language);
  for (const literals of literalsList) {
    literals.setLanguage(language);
  }
}

function init<TLiterals>(literals: TLiterals): TLiterals {
  const literalsMethods = literals as any as LocalizedStringsMethods;

  let language = Cookies.get(COOKIE_LANG_KEY);
  if (!language) language = DEFAULT_LANGUAGE;
  literalsMethods.setLanguage(language);
  literalsList.push(literalsMethods);

  return literals;
}
