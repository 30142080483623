import styled from 'styled-components';

import Container from '../common/Container';

import {mediaQueries} from 'styles/shared';

export const wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5rem 2rem;

  @media ${mediaQueries.mobile} {
    width: 60%;
    padding: 0 2rem 2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const title = styled.div`
  font-size: 2rem;
  text-align: center;

  @media ${mediaQueries.mobile} {
    font-size: 2.8rem;
  }
`;
